import React, { Component,useEffect, useState, useCallback  } from 'react';  
import { Route,Link,NavLink } from 'react-router-dom';  
import createApp from '@shopify/app-bridge';
import { Navigation,Button } from "@shopify/polaris";
import '../../App.css'
import axios from 'axios';

const DashboardLayout = ({children, ...rest},props) => {  
  
  const toggleMobileMenu = () => {

    var nav = document.getElementsByClassName('Polaris-Navigation')[0];

    if (nav.classList.contains('open-menu')) {
      nav.classList.remove('open-menu');
    } else {
      nav.classList.add('open-menu');
    }


  }

  const closeMobileMenu = () => {
    var nav = document.getElementsByClassName('Polaris-Navigation')[0];
    nav.classList.remove('open-menu');
  }

const LogoutUser=()=>{
  localStorage.removeItem("od_ulbdckupfg")
  localStorage.removeItem("us_ulbdckupfg")
  alert("Logged out successfully");
  window.location="/login"
}
  
  
  return (  
    <div className="main">
    <Navigation location="/">
    

      <ul className="Polaris-Navigation__Section Polaris-Navigation__Section--withSeparator">
      { localStorage.getItem('od_ulbdckupfg')?  
        <li className="Polaris-Navigation__ListItem" onClick={() => closeMobileMenu()}>
          <div className="Polaris-Navigation__ItemWrapper">
            <Link to={"/customerlist"} className="Polaris-Navigation__Item">
              <div className="Polaris-Navigation__Icon"><span className="Polaris-Icon">
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 11a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1zM12.363 5.22a4.22 4.22 0 11-8.439 0 4.22 4.22 0 018.439 0zM.67 14.469c1.385-1.09 4.141-2.853 7.474-2.853 3.332 0 6.089 1.764 7.474 2.853.618.486.81 1.308.567 2.056l-.333 1.02A2.11 2.11 0 0113.845 19H2.441a2.11 2.11 0 01-2.005-1.455l-.333-1.02c-.245-.748-.052-1.57.567-2.056z" fill="#5C5F62"/></svg>
              </span></div><span className="Polaris-Navigation__Text">Special Customer Tiers</span>

            </Link>
          </div>
        </li>
        : null}
         { localStorage.getItem('od_ulbdckupfg') ?  
        <li className="Polaris-Navigation__ListItem" onClick={() => closeMobileMenu()}>
          <div className="Polaris-Navigation__ItemWrapper">
            <Link to={"/guestlist"} className="Polaris-Navigation__Item">
              <div className="Polaris-Navigation__Icon"><span className="Polaris-Icon">
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14.363 5.22a4.22 4.22 0 11-8.439 0 4.22 4.22 0 018.439 0zM2.67 14.469c1.385-1.09 4.141-2.853 7.474-2.853 3.332 0 6.089 1.764 7.474 2.853.618.486.81 1.308.567 2.056l-.333 1.02A2.11 2.11 0 0115.846 19H4.441a2.11 2.11 0 01-2.005-1.455l-.333-1.02c-.245-.748-.052-1.57.567-2.056z" fill="#5C5F62"/></svg>
              </span></div><span className="Polaris-Navigation__Text">Guest Customer Tiers</span>

            </Link>
          </div>
        </li>
           : null}
           { localStorage.getItem('od_ulbdckupfg')?  <li className="Polaris-Navigation__ListItem" onClick={() => closeMobileMenu()}>
          <div className="Polaris-Navigation__ItemWrapper">
            <Link to={"/uploadTiers"} className="Polaris-Navigation__Item">
              <div className="Polaris-Navigation__Icon"><span className="Polaris-Icon">
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a.999.999 0 00-1.414 0l-3 3a.999.999 0 101.414 1.414L9 5.414V13a1 1 0 102 0V5.414l1.293 1.293a.999.999 0 101.414-1.414l-3-3zM3 16a1 1 0 100 2h14a1 1 0 100-2H3z" fill="#5C5F62"/></svg>
              </span></div><span className="Polaris-Navigation__Text">Upload Tiers</span>

            </Link>
          </div>
        </li>
            : null}
        
        <li className="Polaris-Navigation__ListItem" onClick={() => closeMobileMenu()}>
          <div className="Polaris-Navigation__ItemWrapper">
            <Link to={"/orderlist"} className="Polaris-Navigation__Item">
              <div className="Polaris-Navigation__Icon"><span className="Polaris-Icon">
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 1a1 1 0 10-2 0v7.586L7.707 7.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 8.586V1z" fill="#5C5F62"/><path d="M3 14V3h4V1H2.5A1.5 1.5 0 001 2.5v15A1.5 1.5 0 002.5 19h15a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0017.5 1H13v2h4v11h-3.5c-.775 0-1.388.662-1.926 1.244l-.11.12A1.994 1.994 0 0110 16a1.994 1.994 0 01-1.463-.637l-.111-.12C7.888 14.664 7.275 14 6.5 14H3z" fill="#5C5F62"/></svg>
              </span></div><span className="Polaris-Navigation__Text">Manage Orders</span>

            </Link>
          </div>
        </li>

      </ul>
      
      
      <div className="nav-plan Polaris-Card__Section--subdued" style={{position: "sticky", textAlign: "center", background: "transparent", top: "100%"}}> 
      {/* <div style={{position: "fixed", bottom: "0",}}> */}
    <Button onClick={LogoutUser} destructive>Logout</Button>
    {/* </div> */}
    </div>
   
    </Navigation>

    <div className="page-container">
      <div className="Polaris-Page">

        <div className="Polaris-Page__Content layout-item btn-open-nav-container" style={{ display: 'none', marginBottom: '0' }} >
          <button type="button" onClick={() => toggleMobileMenu()} className="btn-open-nav Polaris-Button Polaris-Button--sizeLarge Polaris-Button--fullWidth" tabIndex="0" data-polaris-unstyled="true">
            <span className="Polaris-Button__Content">
              <span className="Polaris-Icon" style={{ marginRight: '5px' }}>
                <svg viewBox="0 0 20 20" className="v3ASA" focusable="false" aria-hidden="true"><path d="M19 11H1a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2z"></path></svg>
              </span>
                  Open menu
                </span>
          </button>
        </div>

      {children}
       
      </div>

    </div>




  </div>

  )  
}  
  
const DashboardLayoutRoute = ({component: Component, ...rest}) => {  
  return (  
    <Route {...rest} render={matchProps => (  
      <DashboardLayout>  
          <Component {...matchProps} />  
      </DashboardLayout>  
    )} />  
  )  
};  
  
export default DashboardLayoutRoute;  