import React, { useEffect, useState, useCallback } from "react";
import "./css/productSearchHTML.css";
import "./css/enableDisableSwitch.css";
import {
  ResourceList,
  SettingToggle,
  Page,
  Avatar,
  ResourceItem,
  FooterHelp,
  Loading,
  Heading,
  Toast,
  Frame,
  Spinner,
  Stack,
  Button,
  DisplayText,
  Form,
  FormLayout,
  Card,
  RadioButton,
  TextStyle,
  TextField,
  PageActions,
  Layout,
  TextContainer,
  Modal,
  Link,
} from "@shopify/polaris";
import config from "../config.json";
import axios from "axios";
import logo from "./css/loading.gif"
import Moment from 'react-moment';

const CustomerComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const [tiers, setTiers] = useState([]);

  const [selected, setSelected] = useState("select");

  const [customerId, setCustomerId] = useState(props.match.params.id);
  const [customerName, setCustomerName] = useState(props.match.params.Name);

  const [progressLoading, setProgressLoading] = useState(false);
  const loadingMarkup = progressLoading && <Loading />;
  const [successToggleMesssage, setSuccessToggleMesssage] = useState("");
  const [holdPage, setHoldPage] = useState(false);
  const [productSearchTextField, setproductSearchTextField] = useState();
  const [successActive, setSuccessActive] = useState(false); //success Toast
  const toggleSuccessActive = useCallback(
    () => setSuccessActive((successActive) => !successActive),
    []
  );
  const successToast = successActive ? (
    <Toast content={successToggleMesssage} onDismiss={toggleSuccessActive} />
  ) : null;

  const [paginationObj, setPaginationObj] = useState({});
  let globalLimit = 50;

  useEffect(() => {
    getAllTiers();
  }, [props]);

  //Modal
  var [TierObject, setTierObject] = useState({});
  const [activeToggle, setActiveToggle] = useState(false); //Error Toast Polaris
  const [toggleMesssage, setToggleMesssage] = useState("");
  const toggleMessageChange = useCallback(
    (value) => setToggleMesssage(value),
    []
  );
  const toggleActive = useCallback(
    () => setActiveToggle((activeToggle) => !activeToggle),
    []
  );
  const toastValidationError = activeToggle ? (
    <Toast
      content={toggleMesssage}
      error
      onDismiss={toggleActive}
      duration={4500}
    />
  ) : null;
  const [textFieldValue, setTextFieldValue] = useState();
  const handleTextField = useCallback(
    (newValue) => setTextFieldValue(newValue),
    []
  );
  const [submitButtonText, setSaveTier] = useState("Update Tier");
  const [activeModal, setActiveModal] = useState(false);
  //Modal Trigger start -
  const [obj, setObj] = useState({});
  const handleModalChange = useCallback(
    (
      entity_id,
      entity_title,
      entity_type,
      discount_type,
      created_date,
      tier_status,
      tier_value
    ) => {
      setSaveTier("Update Tier");
      TierObject.entity_id = entity_id;
      TierObject.entity_title = entity_title;
      TierObject.entity_type = entity_type;
      TierObject.discount_type = discount_type;
      TierObject.created_date = created_date;
      TierObject.tier_value = tier_value;
      TierObject.tier_status = tier_status;
      var splittedTier = [];
      try {
        if (splittedTier != null) {
          splittedTier = tier_value.split(",");
        }
      } catch (e) {
        console.log(e);
      }
      if (splittedTier != null) {
        setDivValue("");
        //Assign Modal item
        if (discount_type == "percentage") {
          setradioPercentage(true);
          setradioAmount(false);
          setradioDiscountedPrice(false);
        } else if (discount_type == "fixed") {
          setradioPercentage(false);
          setradioAmount(true);
          setradioDiscountedPrice(false);
        } else {
          setradioPercentage(false);
          setradioAmount(false);
          setradioDiscountedPrice(true);
        } //Assign radio button value

        for (var i = 6; i < splittedTier.length; i++) {
          setDivValue((oldArray) => [...oldArray, i]);
          obj[i - 6] = {
            MinQty: splittedTier[i].split("-")[0],
            MaxQty: splittedTier[i].split("=")[0].split("-")[1],
            Value: splittedTier[i].split("=")[1],
          };
        }
      }
      // setDivValue((oldArray) => [...oldArray, divValue]);
      setActiveModal(!activeModal);
    },
    [activeModal]
  );

  //Modal Trigger end -
  const activatorModal = <Button onClick={handleModalChange}>Open</Button>;
  const [radioPercentage, setradioPercentage] = useState(true);
  const [radioAmount, setradioAmount] = useState(false);
  const [radioDiscountedPrice, setradioDiscountedPrice] = useState(false);

  const ThemeSubmit = async () => { //Update Tier
    setSaveTier("Updating..."); //set submit button loading
    setHoldPage(false);
    var reqFields = [];
    var conditionMaxbyMinCheck = [],
      conditionMinbyAboveMinCheck = [],
      conditionPercentageAmountCheck = [],
      conditionDiscountAmountCheck = [];
    var conditionLastMaxCheck = false;
    var tier_value = ""; // tier value string
    //create logic again for this page
    for (var i = 0; i < divValue.length; i++) {
      var allMinValues = Number(
        document.getElementById("txtRow" + i + "Col1").value
      );

      var allDiscountValues = Number(
        document.getElementById("txtRow" + i + "Col3").value
      );
      var allMinValuesOfAboveRow = 0;
      var allDiscountValuesOfAboveRow = 0;

      if (i < 2) { 
        tier_value = tier_value + allMinValues + "-" + allMinValues + "=" + allDiscountValues + ",";
      } 
      else {
        tier_value = tier_value + allMinValues + "-" + allMinValues + "=" + allDiscountValues;
      }

      //FieldsInput
      if (i > 0) {
        var minus = i - 1;
        allMinValuesOfAboveRow = Number(
          document.getElementById("txtRow" + minus + "Col1").value
        );
        allDiscountValuesOfAboveRow = Number(
          document.getElementById("txtRow" + minus + "Col3").value
        );
      }

      //conditionDiscountAmountCheck
      if (i > 0) {
        if (radioDiscountedPrice) {
          if (allDiscountValues <= allDiscountValuesOfAboveRow) {
            conditionDiscountAmountCheck.push(true);
          } else {
            conditionDiscountAmountCheck.push(false);
          }
        } else {
          if (allDiscountValues >= allDiscountValuesOfAboveRow) {
            conditionDiscountAmountCheck.push(true);
          } else {
            conditionDiscountAmountCheck.push(false);
          }
        }
      }
      //ValidationMinToPreMinValueCheck
      if (i > 0) {
        if (allMinValues >= allMinValuesOfAboveRow) {
          conditionMinbyAboveMinCheck.push(true);
        } else {
          conditionMinbyAboveMinCheck.push(false);
        }
      }
    }

    //FieldsRequiredValidationCheck
    if ( allMinValues == null || allMinValues == "" || allDiscountValues == null || allDiscountValues == "" ) {
      reqFields.push(false);
    } else {
      reqFields.push(true);
    }
    //AllAlerts
    if (reqFields.includes(false)) {
      //1 Condititon field required Check
      toggleMessageChange("Fields Required");
      setActiveToggle(true);
    } else if (conditionMinbyAboveMinCheck.includes(false)) {
      //2 MaxMIn Condititon Check
      toggleMessageChange(
        "Quantity values should be greater or equal to previous Quantity values"
      );
      setActiveToggle(true);
    } else if (radioDiscountedPrice || !radioDiscountedPrice) {
      //4,5,6 Radio Button, Discount value, LastField Condititon Check
      if (radioDiscountedPrice) {
        if (conditionDiscountAmountCheck.includes(false)) {
          toggleMessageChange(
            "Discount value should be less than or equal to previous Discount value"
          );
          setActiveToggle(true);
        } else {
          setSaveTier("Create Tier");
        }
      } else {
        if (conditionDiscountAmountCheck.includes(false)) {
          toggleMessageChange(
            "Discount value should be greater or equal to previous Discount value"
          );
          setActiveToggle(true);
        } else {
          setSaveTier("update Tier");
        }
      }
    }

    var radioButtonValue = ""; //Get Discount type value
    if (radioPercentage) {
      radioButtonValue = "percentage";
    } else if (radioAmount) {
      radioButtonValue = "fixed";
    } else if (radioDiscountedPrice) {
      radioButtonValue = "fixed_price";
    }

    //All coditions Check & Submit
    if ( !reqFields.includes(false) && !conditionMinbyAboveMinCheck.includes(false) && !conditionDiscountAmountCheck.includes(false) ) {
      setSaveTier("Update Tier");
      TierObject.discount_type = radioButtonValue;
      TierObject.tier_value = tier_value;
      var data = { TierObject: TierObject };

      setSaveTier("Updating...");
      setProgressLoading(true);
      setHoldPage(true);
      axios
        .post(config.APIURL + "/normalTier/EditNormalTiers", data) //submit data
        .then(async(res) => {
          console.log(res);
          setActiveModal(!activeModal);
          // getAllTiers();
          //Call List again on the existing page  
          var _url_ = config.APIURL + "/normalTier/getNormalTierList";
          var datatosend = { offset: paginationObj.offset, limit: globalLimit, query:productSearchTextField };
          var refresh_ = await axios.post(_url_, datatosend)
          var Tiers = refresh_.data.tierList.docs;
          setTiers(Tiers);
          //Call List again on the existing page          
          setSuccessToggleMesssage("Tier Updated Sucessfully");
          setSuccessActive(true); //Toast
          setProgressLoading(false);
          setHoldPage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSaveTier("Update Tier");
    }
  };

  let [divValue, setDivValue] = useState([1, 1, 1]);
  const handleRadio = useCallback((_checked, newValue) => { //Change radio discount value
    switch (newValue) {
      case "r_percentage":
        setradioPercentage(true);
        setradioAmount(false);
        setradioDiscountedPrice(false);
        break;
      case "r_amount":
        setradioPercentage(false);
        setradioAmount(true);
        setradioDiscountedPrice(false);
        break;
      case "r_discount":
        setradioPercentage(false);
        setradioAmount(false);
        setradioDiscountedPrice(true);
        break;
      default:
        setradioPercentage(true);
        setradioAmount(false);
        setradioDiscountedPrice(false);
    }
  }, []);
  //Modal

 
  //Tier search from db
  const handleSearch = useCallback(
    async (newValue) => {
      setproductSearchTextField(newValue);
      var url_ = config.APIURL + "/normalTier/getNormalTierList";
      var data = { offset: 0, limit: globalLimit ,query:newValue};
      axios
        .post(url_, data)
        .then((res) => {
          console.log(res);
          var data = res.data.tierList.docs;
          paginationObj.hasNextPage = res.data.tierList.hasNextPage;
          paginationObj.hasPrevPage = res.data.tierList.hasPrevPage;
          paginationObj.limit = res.data.tierList.limit;
          paginationObj.nextPage = res.data.tierList.nextPage;
          paginationObj.offset = res.data.tierList.offset;
          paginationObj.page = res.data.tierList.page;
          paginationObj.pagingCounter = res.data.tierList.pagingCounter;
          paginationObj.prevPage = res.data.tierList.prevPage;
          paginationObj.totalDocs = res.data.tierList.totalDocs;
          paginationObj.totalPages = res.data.tierList.totalPages;
  
          var Tiers = res.data.tierList.docs;
  
          setTiers(Tiers);
          setProgressLoading(false);
          setHoldPage(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }  
  );
   
  //product list with pagination
  const getAllTiers = () => {
    setProgressLoading(true);
    setLoading(true);
    setproductSearchTextField('');
    var url_ = config.APIURL + "/normalTier/getNormalTierList";
    var data = { offset: 0, limit: globalLimit };
    axios
      .post(url_, data)
      .then((res) => {
        console.log(res);
        var data = res.data.tierList.docs;
        paginationObj.hasNextPage = res.data.tierList.hasNextPage;
        paginationObj.hasPrevPage = res.data.tierList.hasPrevPage;
        paginationObj.limit = res.data.tierList.limit;
        paginationObj.nextPage = res.data.tierList.nextPage;
        paginationObj.offset = res.data.tierList.offset;
        paginationObj.page = res.data.tierList.page;
        paginationObj.pagingCounter = res.data.tierList.pagingCounter;
        paginationObj.prevPage = res.data.tierList.prevPage;
        paginationObj.totalDocs = res.data.tierList.totalDocs;
        paginationObj.totalPages = res.data.tierList.totalPages;

        var Tiers = res.data.tierList.docs;

        setTiers(Tiers);
        setProgressLoading(false);
        setHoldPage(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNextList = () => { //Next page
    if (paginationObj.hasNextPage) {
      setProgressLoading(true);
      setHoldPage(true);
      setLoading(true);
      var url_ = config.APIURL + "/normalTier/getNormalTierList";
      var data = {
        offset: paginationObj.offset + paginationObj.limit,
        limit: globalLimit,
        query:productSearchTextField
      };
      axios
        .post(url_, data)
        .then((res) => {
          console.log(res);
          var data = res.data.tierList.docs;
          paginationObj.hasNextPage = res.data.tierList.hasNextPage;
          paginationObj.hasPrevPage = res.data.tierList.hasPrevPage;
          paginationObj.limit = res.data.tierList.limit;
          paginationObj.nextPage = res.data.tierList.nextPage;
          paginationObj.offset = res.data.tierList.offset;
          paginationObj.page = res.data.tierList.page;
          paginationObj.pagingCounter = res.data.tierList.pagingCounter;
          paginationObj.prevPage = res.data.tierList.prevPage;
          paginationObj.totalDocs = res.data.tierList.totalDocs;
          paginationObj.totalPages = res.data.tierList.totalPages;

          var Tiers = res.data.tierList.docs;

          setTiers(Tiers);
          setProgressLoading(false);
          setHoldPage(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //product list with pagination
  const handlePrevList = () => {
    if (paginationObj.hasPrevPage) {
      setProgressLoading(true);
      setLoading(true);
      setHoldPage(true);
      var url_ = config.APIURL + "/normalTier/getNormalTierList";
      var data = {
        offset: paginationObj.offset - paginationObj.limit,
        limit: globalLimit,
        query:productSearchTextField
      };
      axios
        .post(url_, data)
        .then((res) => {
          console.log(res);
          var data = res.data.tierList.docs;
          paginationObj.hasNextPage = res.data.tierList.hasNextPage;
          paginationObj.hasPrevPage = res.data.tierList.hasPrevPage;
          paginationObj.limit = res.data.tierList.limit;
          paginationObj.nextPage = res.data.tierList.nextPage;
          paginationObj.offset = res.data.tierList.offset;
          paginationObj.page = res.data.tierList.page;
          paginationObj.pagingCounter = res.data.tierList.pagingCounter;
          paginationObj.prevPage = res.data.tierList.prevPage;
          paginationObj.totalDocs = res.data.tierList.totalDocs;
          paginationObj.totalPages = res.data.tierList.totalPages;

          var Tiers = res.data.tierList.docs;

          setTiers(Tiers);

          setProgressLoading(false);
          setHoldPage(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //Enable Disable tier
  const enableDisableTier = ( entity_id, entity_title, entity_type, discount_type, created_date, tier_status, tier_value ) => {
    setProgressLoading(true);
    setHoldPage(true);
    if (tier_status) { tier_status = false; } else { tier_status = true; }
    if (tier_value.indexOf("true") !== -1) {
      tier_value = tier_value.replace("true", "false");
    } else {
      tier_value = tier_value.replace("false", "true");
    }
    var url = config.APIURL + "/normalTier/enabledisableTier"; //Enable Or Disable the tier
    var data = { entity_id: entity_id, tier_value: tier_value, tier_status: tier_status };
    axios
      .post(url, data)
      .then(async (res) => {
        if (tier_status) {
          setSuccessToggleMesssage("Tier Enabled Successfully");
        } //Success Toast
        else {
          setSuccessToggleMesssage("Tier Disabled Successfully");
        }
        setSuccessActive(true);

        //Call List again on the existing page  
        var _url_ = config.APIURL + "/normalTier/getNormalTierList";
        var datatosend = { offset: paginationObj.offset, limit: globalLimit, query:productSearchTextField };
        var refresh_ = await axios.post(_url_, datatosend)
        var Tiers = refresh_.data.tierList.docs;
        setTiers(Tiers);
        //Call List again on the existing page
        setProgressLoading(false);
        setHoldPage(false);
        // getAllTiers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var checkboxes = document.getElementsByClassName("checkboxes");
  var checkAll = document.getElementsByClassName("check-all");
  var delBulk = [];
  const allCheckbox = () => {
    // alert(checkAll[0].checked)
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkAll[0].checked) {
        checkboxes[i].checked = true;
      } else {
        checkboxes[i].checked = false;
      }
    }
  };
  const deleteBukTier = () => {
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        delBulk.push(checkboxes[i].name);
      }
    }
    if(delBulk.length == 0){
    }else{
      setProgressLoading(true);
      setHoldPage(true);
      var url = config.APIURL + "/normalTier/deleteBulktier";
      var data = { entity_id: delBulk };
      axios
        .post(url, data)
        .then(async (res) => {
          setSuccessToggleMesssage("Tier Deleted Successfully");
          setSuccessActive(true);
          checkAll[0].checked = false;
          allCheckbox();
          //Call List again on the existing page  
          if(paginationObj.offset == 0){
            getAllTiers();
          }
          else{
            handlePrevList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
   
  };
  const exportTiers = async () => {
    var export_ =  await axios.get(config.APIURL + '/export/ExportTiers');
    console.log(export_.data.NormalTiersExport)
  }
  const redirect = () => {
    window.location.href = "/create-guest-customer-tiers";
  };

  const handleSearchClearButton = useCallback( () => {
    setproductSearchTextField("")
    getAllTiers();
    setLoading(false); 
  }, [] );

  const [entityIdToDelete, setEntityIdToDelete] = useState(0);
  const [active_, setActive_] = useState(false);
  const handleChange_ = useCallback((id) => { 
    setActive_(!active_);
    setEntityIdToDelete(id);
  }, [active_]);
  const activator_ = <Button onClick={handleChange_}>Open</Button>;

  const deleteTier = () => {
    setProgressLoading(true);
    setHoldPage(true);
    var url = config.APIURL + "/normalTier/deleteNormaltier";
    console.log(url);
    var data = { entity_id: entityIdToDelete };
    console.log(data);
    axios
      .post(url, data)
      .then(async (res) => {
        setActive_(false);
        setSuccessActive(true);
        setSuccessToggleMesssage("Tier Deleted Successfully");
        //Call List again on the existing page  
        var _url_ = config.APIURL + "/normalTier/getNormalTierList";
        var datatosend = { offset: paginationObj.offset, limit: globalLimit, query:productSearchTextField };
        var refresh_ = await axios.post(_url_, datatosend)
        var Tiers = refresh_.data.tierList.docs;
        setTiers(Tiers);
        //Call List again on the existing page
        setProgressLoading(false);
        setHoldPage(false);
        // getAllTiers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}>
      {/* {holdPage ?
      <div className="loading-spinner">
      <Spinner accessibilityLabel="Spinner example" size="large" color="teal" /> 
      </div> : null
      } */}
      
      
      <Frame>
        <Page
         
          primaryAction={
          <button className="Polaris-Button Polaris-Button--primary" type="button"><span className="Polaris-Button__Content">
          <span className="Polaris-Button__Text">
          <a href={config.APIURL + '/export/ExportTiers'}>Export Tiers</a>
          </span></span>
          </button>
        }
          title={"Guest Customer Tiers"}
        >
          {/* <Button>Add product</Button> */}
          <PageActions
            primaryAction={{
              content: "Create Tiers",
              onClick: redirect, //create button
            }}
            secondaryActions={[
              {
                content: "Delete Tiers",
                destructive: true,
                onClick: deleteBukTier, //delete button
              },
            ]}
          />

        <Modal
          activator={activator_}
          open={active_}
          onClose={handleChange_}
          title="Confirm Delete"
          primaryAction={{
            content: 'Delete',
            onAction: deleteTier,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleChange_,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                Are you sure you want to delete?
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>          

          {loadingMarkup}
          <Modal
            activator={activatorModal}
            open={activeModal}
            onClose={handleModalChange}
            title="Edit Discount"
            primaryAction={{ content: submitButtonText, onAction: ThemeSubmit }}
            secondaryActions={[
              { content: "Cancel", onAction: handleModalChange },
            ]}
          >
            <div className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}>
            {/* {holdPage ?
      <div className="loading-modal-spinner">
      <Spinner accessibilityLabel="Spinner example" size="large" color="teal" /> 
      </div> : null
      } */}
              <Form>
                <Card>
                  <Card.Section>
                    {/* <Modal.Section> */}
                    <div className="custom-margin-bottom">
                      <p>
                        Identifier Type:{" "}
                        <TextStyle variation="subdued">
                          {TierObject.entity_type}
                        </TextStyle>
                      </p>
                    </div>
                    <div className="custom-margin-bottom">
                      <p>
                        Identifier Title:{" "}
                        <TextStyle variation="subdued">
                          {TierObject.entity_title}
                        </TextStyle>
                      </p>
                    </div>
                    <div className="custom-margin-bottom">
                      <p>
                        <Stack distribution="fill">
                          <p>
                            Discount Type:{" "}
                            <TextStyle variation="subdued"></TextStyle>
                          </p>
                          <RadioButton
                            label="Percentage Off"
                            checked={radioPercentage}
                            id="r_percentage"
                            name="percentage"
                            onChange={handleRadio}
                          />
                          <RadioButton
                            label="Amount Off"
                            checked={radioAmount}
                            id="r_amount"
                            name="amount"
                            onChange={handleRadio}
                          />
                          <RadioButton
                            label="Discounted Price"
                            checked={radioDiscountedPrice}
                            id="r_discount"
                            name="discount"
                            onChange={handleRadio}
                          />
                        </Stack>
                      </p>
                    </div>
                    <Stack distribution="fillEvenly">
                      {/* <div className="custom-margin-bottom"><p>Minimum Quantity</p></div>
                  <div className="custom-margin-bottom"><p>Maximum Quantity</p></div>
                  <div className="custom-margin-bottom"><p>Discount Value</p></div> */}
                  
                    </Stack>
                    <div className="custom-margin-bottom">
                    </div>
                    {divValue.length > 0 ? (
                      <div className="">
                        {divValue.length > 0
                          ? divValue.map((item, i) => (
                              <span id={item} key={i} className="">
                                <Stack distribution="fillEvenly">
                                  <div className="">
                                    {" "}
                                    <div className="Polaris-Connected">
                                      {" "}
                                      <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                        {" "}
                                        <div className="Polaris-TextField">
                                          {" "}
                                          <input
                                            type="number"
                                            id={"txtRow" + i + "Col1"}
                                            className={
                                              "Polaris-TextField__Input txtRow" +
                                              i +
                                              "Col1"
                                            }
                                            aria-labelledby="PolarisTextField5Label"
                                            aria-invalid="false"
                                            aria-multiline="false"
                                            defaultValue={
                                              obj[i] != null
                                                ? obj[i].MinQty
                                                : ""
                                            }
                                          ></input>{" "}
                                          <div className="Polaris-TextField__Backdrop"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="" > <div className="Polaris-Connected"> <div className="Polaris-Connected__Item Polaris-Connected__Item--primary"> <div className="Polaris-TextField"> <input type="number" id={"txtRow" + i + "Col2"} className={"Polaris-TextField__Input txtRow" + i + "Col2"} aria-labelledby="PolarisTextField5Label" aria-invalid="false" aria-multiline="false" defaultValue={obj[i] != null ? obj[i].MaxQty : ''}></input> <div className="Polaris-TextField__Backdrop"></div></div></div></div></div> */}
                                  <div className="custom-margin-bottom">
                                    {" "}
                                    <div className="Polaris-Connected">
                                      {" "}
                                      <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                        {" "}
                                        <div className="Polaris-TextField">
                                          {" "}
                                          <input
                                            type="number"
                                            id={"txtRow" + i + "Col3"}
                                            className={
                                              "Polaris-TextField__Input txtRow" +
                                              i +
                                              "Col3"
                                            }
                                            aria-labelledby="PolarisTextField5Label"
                                            aria-invalid="false"
                                            aria-multiline="false"
                                            defaultValue={
                                              obj[i] != null ? obj[i].Value : ""
                                            }
                                          ></input>{" "}
                                          <div className="Polaris-TextField__Backdrop"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Stack>
                              </span>
                            ))
                          : null}
                      </div>
                    ) : null}

                    {/* <Stack distribution="fill">
                      <div>Start Date</div>
                      <div>End Date</div>
                    </Stack>

                    <Stack distribution="fill">
                      <TextField
                        label=""
                        value={textFieldValue}
                        onChange={handleTextField}
                      />
                      <TextField
                        label=""
                        value={textFieldValue}
                        onChange={handleTextField}
                      />
                    </Stack> */}
                  </Card.Section>
                </Card>
              </Form>
              {/* </Modal.Section> */}
            </div>
          </Modal>
          <Card>
            <Card.Section>
              <TextField
              value={productSearchTextField}
                placeholder="Search Tiers"       
                onChange={handleSearch}
                autoComplete="off"
                clearButton
                onClearButtonClick={handleSearchClearButton}
              ></TextField>
            </Card.Section>

            <table>
              <thead className="table-thead">
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      className="check-all"
                      name="subscribe"
                      onClick={() => allCheckbox()}
                      style={{ marginLeft: "26px", marginRight: "-39px" }}
                    ></input>
                  </td>
                  <td
                    className="thead-th"
                    style={{ width: "380px", paddingLeft: "52px" }}
                  >
                    Title
                  </td>
                  <td
                    className="thead-th"
                    style={{ width: "120px", paddingLeft: "52px" }}
                  >
                    Type
                  </td>
                  <td
                    className="thead-th"
                    style={{ width: "240px", paddingLeft: "52px" }}
                  >
                    Created Date
                  </td>
                  <td
                    className="thead-th"
                    style={{ width: "60px", paddingLeft: "42px" }}
                  >
                    Status
                  </td>
                  <td
                    className="thead-th"
                    style={{ width: "80px", paddingLeft: "25px" }}
                  >
                    Actions
                  </td>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <ResourceList
              resourceName={{ singular: "Tier", plural: "Tiers" }}
              items={tiers}
              renderItem={(item) => {
                const {
                  entity_id,
                  entity_title,
                  entity_type,
                  created_date,
                  tier_status,
                  discount_type,
                  tier_value,
                } = item;
                return (
                  <ResourceItem
                    id={entity_id}
                    // url={'/customer/' + entity_id}
                    accessibilityLabel={`View details for ${entity_title}`}
                  >
                    <Stack alignment="center">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                name={entity_id}
                                className="checkboxes"
                                type="checkbox"
                                style={{ marginRight: "10px" }}
                              ></input>
                            </td>
                            <td className="" style={{ width: "380px" }}>
                              {entity_title}
                            </td>
                            <td className="" style={{ width: "120px" }}>
                              {entity_type}
                            </td>
                            <td className="" style={{ width: "235px" }}>
                            {/* <Moment subtract={{ hours: 5 }} format="DD/MM/YYYY hh:mm">{created_date}</Moment> */}
                            {/* https://www.npmjs.com/package/react-moment //REACCT DATE TIME FORMATS */}
                            <Moment local format="DD/MM/YYYY HH:mm">{created_date}</Moment>
                            </td>
                            <td className="" style={{ width: "70px" }}>
                              <span
                                onClick={() => {
                                  enableDisableTier(
                                    entity_id,
                                    entity_title,
                                    entity_type,
                                    discount_type,
                                    created_date,
                                    tier_status,
                                    tier_value
                                  );
                                }}
                                title={
                                  tier_status ? "Enabled" : "Disabled"
                                }
                              >
                                <div className={tier_status ? "Switch Round Off" : "Switch Round On" }>
                                  <div className="Toggle"></div>
                                </div>
                                {/* <i
                                  className={
                                    tier_status == true
                                      ? "fas fa-toggle-on fa-1.5x"
                                      : "fas fa-toggle-off fa-1.5x"
                                  }
                                ></i> */}
                              </span>
                            </td>
                            <td className="" style={{ width: "60px" }}>
                              <span
                                onClick={() =>
                                  handleModalChange(
                                    entity_id,
                                    entity_title,
                                    entity_type,
                                    discount_type,
                                    created_date,
                                    tier_status,
                                    tier_value
                                  )
                                }
                                title="Edit"
                              >
                                <i className="fas fa-edit fa-1.5x"></i>
                              </span>
                              <span
                                onClick={() => {
                                  handleChange_(entity_id);
                                }}
                                style={{ paddingLeft: "20px" }}
                                title="Close"
                              >
                                <i className="fas fa-close fa-1.8x"></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Stack>
                  </ResourceItem>
                );
              }}
              isFiltered={false}
              showHeader={false}
              // selectable
            />

            <Card.Section>
              <Stack spacing="loose" alignment="center" distribution="center">
                {paginationObj.hasPrevPage == true ? (
                  <Button onClick={handlePrevList}>Previous</Button>
                ) : (
                  <Button onClick={handlePrevList} disabled>
                    Previous
                  </Button>
                )}
                <span>{paginationObj.page}</span>
                {paginationObj.hasNextPage == true ? (
                  <Button onClick={handleNextList}>Next</Button>
                ) : (
                  <Button onClick={handleNextList} disabled>
                    Next
                  </Button>
                )}
              </Stack>
            </Card.Section>
          </Card>
        </Page>
        {successToast}
        {toastValidationError}
      </Frame>
    </div>
  );
};

export default CustomerComponent;
