import React, { useEffect, useState, useCallback } from "react";
import "./css/productSearchHTML.css";
import {
  ResourceList,
  SettingToggle,
  Page,
  Avatar,
  ResourceItem,
  FooterHelp,
  Loading,
  Heading,
  Toast,
  Frame,
  Stack,
  Button,
  ProgressBar,
  DisplayText,
  Form,
  FormLayout,
  Card,
  Thumbnail,
  Caption,
  RadioButton,
  TextStyle,
  TextField,
  Banner,
  PageActions,
  Layout,
  TextContainer,
  Modal,
  Spinner,
  List,
  DropZone,
  Link,
} from "@shopify/polaris";
import "./css/productSearchHTML.css";
import config from "../config.json";
import axios from "axios";
const UploadTier = (props) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingValue, setLoadingValue] = useState(0);
  const [holdPage, setHoldPage] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const loadingMarkup = progressLoading && <Loading />;
  const [SampleButton, setSampleButton] = useState('Download sample file for Guest Customer Tiers');
  const [radioNormal, setradioNormal] = useState(true);
  const [radioCustomer, setradioCustomer] = useState(false);
  const [successActive, setSuccessActive] = useState(false); //success Toast
  const [successToggleMesssage, setSuccessToggleMesssage] = useState("");
  const toggleSuccessActive = useCallback(() => setSuccessActive((successActive) => !successActive), []);
  const successToast = successActive ? (
    <Toast content={successToggleMesssage} onDismiss={toggleSuccessActive} />
  ) : null;
  const handleRadio = useCallback((_checked, newValue) => {
    switch (newValue) {
      case "r_NormalTier": setradioNormal(true); setradioCustomer(false); setSampleButton('Download sample file for Guest Customer Tiers')
        break;
      case "r_CustomerTier": setradioNormal(false); setradioCustomer(true); setSampleButton('Download sample file for Special Customer Tiers')
        break;
      default: setradioNormal(true); setradioCustomer(false);
    }
  }, []);

  var radioButtonValue = !radioNormal ? "customer" : "normal"; //Get Discount type value

  const UploadLoopFunc = async () => {
    var checkUpload = await axios.get(config.APIURL + '/upload/isUploading');
    var UploadStatus = checkUpload.data.UploadingStatus.status;
    var UploadValue = checkUpload.data.UploadingStatus.value;
    if(UploadStatus){
      setLoadingStatus(UploadStatus);
      setLoadingValue(UploadValue);
      setTimeout(async () => {
        UploadLoopFunc();
      },1000);
    }
    else{
      setLoadingStatus(UploadStatus);
      setLoadingValue(UploadValue)
    }
  }

  useEffect( () => {
    async function loadCheck() {
      var checkUpload = await axios.get(config.APIURL + '/upload/isUploading');
      var UploadStatus = checkUpload.data.UploadingStatus.status;
      var UploadValue = checkUpload.data.UploadingStatus.value;
      if(UploadStatus){
        setLoadingStatus(true);
        UploadLoopFunc();
      }
    }
    loadCheck();

    
  }, [props]);
  //DropZoneArea Start
  const [files, setFiles] = useState();
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((file) => acceptedFiles[0]),
    [],
  );

  const uploadedFiles = files && (
    <Stack>
      <Thumbnail
        size="small"
        alt='CSV'
        source="https://img.icons8.com/dotty/2x/import-csv.png"
      />
      <div>
        {files.name} <Caption>{files.size} bytes</Caption>
      </div>
    </Stack>
  );

  const [validationMessage, setValidationMessage] = useState(false);
  const [identifierType, setIdentifierType] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [tierValue, setTierValue] = useState('');
  const [invalidCsv, setInvalidCsv] = useState('');
  const upload = async () => {
    setValidationMessage(false)
      if(files !== undefined && files != null){       
          const formData = new FormData();        
          formData.append('file', files); // appending file
          setHoldPage(true);
          setProgressLoading(true);
          if(radioButtonValue == 'normal'){
            var upload = await axios.post(config.APIURL + "/upload/UploadNormalTiers", formData); //submit data
            console.log(upload)
            if(upload.data.status == "success"){
              if(upload.data.ValidationObject.IdentifierType != null || upload.data.ValidationObject.DiscountType != null || upload.data.ValidationObject.TierValue != null || upload.data.ValidationObject.InvalidCSV != null){
                setValidationMessage(true)
                setFiles();
                setIdentifierType(upload.data.ValidationObject.IdentifierType)
                setDiscountType(upload.data.ValidationObject.DiscountType)
                setTierValue(upload.data.ValidationObject.TierValue)
                setInvalidCsv(upload.data.ValidationObject.InvalidCSV)
              }
              else{
              setSuccessToggleMesssage("Tiered upload is initiated. This could take some time to complete.");
              setSuccessActive(true);
              setFiles();
              UploadLoopFunc();
                setValidationMessage(false)
              }
              setHoldPage(false);
              setProgressLoading(false);
            }
          }
          else{
            var upload = await axios.post(config.APIURL + "/upload/UploadCustomerTiers", formData); //submit data
            if(upload.data.status == "success"){
              if(upload.data.ValidationObject.IdentifierType != null || upload.data.ValidationObject.DiscountType != null || upload.data.ValidationObject.TierValue != null || upload.data.ValidationObject.InvalidCSV != null){
                setValidationMessage(true)
                setFiles();
                setIdentifierType(upload.data.ValidationObject.IdentifierType)
                setDiscountType(upload.data.ValidationObject.DiscountType)
                setTierValue(upload.data.ValidationObject.TierValue)
                setInvalidCsv(upload.data.ValidationObject.InvalidCSV)
              }
              else{
              setSuccessToggleMesssage("Tiered upload is initiated. This could take some time to complete.");
              setSuccessActive(true);
              setFiles();
              UploadLoopFunc();
                setValidationMessage(false)
              }
              setHoldPage(false);
              setProgressLoading(false);
            }
          }
      }
      else{
        alert('Please Select File')
        setValidationMessage(false)
        setHoldPage(false);
        setProgressLoading(false);
      }
  };
const handleSubmit =()=>{
alert("hi")
var data = {
 
};
axios.post(config.APIURL + "/upload/GenerateCustomerTiers", data) //submit data
      .then((res) => {
        console.log(res);
        
        
      })
      .catch((err) =>{
        console.log(err);
      })

}
  const errorMessage = hasError && (
    <Banner
      title="The following fILE couldn’t be uploaded:"
      status="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .csv.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );
  //DropZoneEnds


  return (
    <div className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}>     
    <Frame>
      <Page title="Upload Tiers">
        <PageActions/>
        {loadingMarkup}
        <Card>
          <Card.Section>
            <DisplayText size="small">Select Tier Type</DisplayText>
            <Stack spacing="loose" >
              <RadioButton
                label="Guest Customer Tiers"
                checked={radioNormal}
                id="r_NormalTier"
                name="NormalTier"
                onChange={handleRadio}
              />
              <RadioButton
                label="Special Customer Tiers"
                checked={radioCustomer}
                id="r_CustomerTier"
                name="CustomerTier      "
                onChange={handleRadio}
              />
            </Stack>
            <a href={config.APIURL+'/upload/GenerateCustomerTiers?NormalTier='+radioNormal}>{SampleButton}</a>
            {/* <Button onClick={handleSubmit} plain>{SampleButton}</Button> */}
          </Card.Section>
          
          <Card.Section>
            <Stack vertical>
              {errorMessage}
              <DropZone
              allowMultiple={false} 
                accept=".csv"
                type="file"
                errorOverlayText="File type must be .csv"
                onDrop={handleDropZoneDrop}
              >
                {uploadedFiles}
              </DropZone>

              {loadingStatus ? 
                <Stack.Item >

                  <div>Tiers are being created, please wait until the process is complete.</div>
                  <div style={{width:'50%', float:'left', marginRight:'10px'}}>
                  <ProgressBar progress={loadingValue} />
                  </div>
                    <div>{loadingValue}%</div>
                </Stack.Item>
              : null }

              <Stack.Item fill>
                <Button primary onClick={upload} disabled={loadingStatus ? true : false}>
                {holdPage ? null : <div>Upload Tiers</div> }
                  {holdPage ?<Spinner accessibilityLabel="Spinner example" size="small" color="teal" /> :null}
                  </Button>
              </Stack.Item>
            </Stack> 
          </Card.Section>
          
          {validationMessage == true ?
          <Card.Section>

            {invalidCsv != null ? 
              <div style={{overflowWrap: 'break-word'}}> <span style={{color: 'red'}}> {invalidCsv} </span> </div>
             : null}

             {identifierType != null ? 
              <div style={{overflowWrap: 'break-word'}}> <span style={{color: 'red'}}> Validation error in Identifier Type at rows: </span>{identifierType} </div>
             : null}
            
            {discountType != null ? 
              <div style={{overflowWrap: 'break-word'}}> <span style={{color: 'red'}}> Validation error in Discount Type at rows: </span>{discountType} </div>
             : null}

             {tierValue != null ? 
              <div style={{overflowWrap: 'break-word'}}> <span style={{color: 'red'}}> Validation error in Discount value at rows: </span>{tierValue} </div>
             : null}
          </Card.Section>
          : null
          }

      </Card>
      </Page>
      {successToast}
    </Frame>
    </div>
  );
};
export default UploadTier;
