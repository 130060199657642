import React, { useEffect, useState, useCallback } from "react";
//import Cookies from 'js-cookie';
import createApp from '@shopify/app-bridge';
//-------------------------------new implementation

import { BrowserRouter as Router, Switch, Route,Redirect, Link } from "react-router-dom";
import CustomerList from "./components/customerlist.component";
import GuestList from "./components/guestlist.component";
import ThemeLibrary from "./components/theme-library.component";
import Plans from "./components/plans.component"
import Training from "./components/training.component"
//---------------------------------------------------
import { Navigation } from "@shopify/polaris";
import './App.css'
import axios from 'axios';
import Support from "./components/support.component";
import AddOns from "./components/addons.component";
import Login from "./components/login";

/** Layouts **/  
import LoginLayoutRoute from "./components/layout/LoginLayout";  
import DashboardLayoutRoute from "./components/layout/DashboardLayout";  
import CustomerComponent from "./components/customer.component";
import CreatePage from "./components/create.component";
import CreateGuestPage from "./components/createGuest.component";
import testpage from "./components/TestPage";
import UploadTiers from "./components/uploadTiers.component";
import OrderListComponent from "./components/orderlist.component";
import OrderDetail from "./components/orderDetail"
const App = (props) => {
  const [currentPlan,setCurrentPlan]=useState("");
// alert(localStorage.getItem('wahaj'))
return (
<Router>  
        <Switch>  
          <Route exact path="/">  
            <Redirect to="/login" />  
          </Route>  
          
          <LoginLayoutRoute path="/login" component={Login} />  
        
          { localStorage.getItem('us_ulbdckupfg')    ? <DashboardLayoutRoute path="/orderlist" component={OrderListComponent} /> : <Redirect to="/login" /> } 
          { localStorage.getItem('us_ulbdckupfg')    ? <DashboardLayoutRoute path="/orderdetail/:id" component={OrderDetail} /> : <Redirect to="/login" /> } 
          { localStorage.getItem('od_ulbdckupfg') ? <DashboardLayoutRoute path="/customerlist" component={CustomerList} /> : <Redirect to="/login" /> }
          { localStorage.getItem('od_ulbdckupfg')? <DashboardLayoutRoute path="/guestlist" component={GuestList} /> : <Redirect to="/login" /> }
          { localStorage.getItem('od_ulbdckupfg') ? <DashboardLayoutRoute path="/create-special-customer-tiers" component={CreatePage} /> : <Redirect to="/login" /> }
          { localStorage.getItem('od_ulbdckupfg') ? <DashboardLayoutRoute path="/create-guest-customer-tiers" component={CreateGuestPage} /> : <Redirect to="/login" /> }  
          { localStorage.getItem('od_ulbdckupfg') ? <DashboardLayoutRoute path="/testpage" component={testpage} /> : <Redirect to="/login" /> } 
          { localStorage.getItem('od_ulbdckupfg') ? <DashboardLayoutRoute path="/uploadTiers" component={UploadTiers} /> : <Redirect to="/login" /> } 
          { localStorage.getItem('od_ulbdckupfg') ? <DashboardLayoutRoute path="/customer/:id/:Name/:email" component={CustomerComponent} /> : <Redirect to="/login" /> } 
         
        </Switch>  
      </Router> 
  );
}




export default App;