import React, { useEffect, useState, useCallback } from "react";
import {
  ResourceList,
  Page,
  Avatar,
  ResourceItem,
  FooterHelp,
  Loading,
  Heading,
  Toast,
  Frame,
  Stack,
  Button,
  Card,
  TextStyle,
  PageActions,
  Layout,
  Link,
  TextField,
  Spinner,
} from "@shopify/polaris";
import axios from "axios";
import config from "../config.json";
import { PageItem } from "react-bootstrap";
import Moment from "react-moment";
import "./css/productSearchHTML.css";

const OrderListComponent = (props) => {
  const [customerList, setCustomerList] = useState("");
  const [nextUrl, setNextUrl] = useState("");
  const [previousUrl, setPreviousUrl] = useState("");
  const [hasNextUrl, setHasNextUrl] = useState(false);
  const [hasPreviousUrl, setHasPreviousUrl] = useState(false);

  const [progressLoading, setProgressLoading] = useState(false);
  const loadingMarkup = progressLoading && <Loading />;
  const [customerSearchTextField, setcustomerSearchTextField] = useState();
  const [errorActive, setErrorActive] = useState({
    activate: false,
    errorMessage: "",
  });
  const [loading, setLoading] = useState(false);
  let globalLimit = 50;

  const toastMarkup = errorActive.activate ? (
    <Frame>
      {" "}
      <Toast
        content={errorActive.errorMessage}
        onDismiss={() => setErrorActive({ activate: false, errorMessage: "" })}
      />
    </Frame>
  ) : null;

  useEffect(() => {
    getAllOrder();
  }, [props]);

  const [paginationObj, setPaginationObj] = useState({});

  const getAllOrder = () => {
    setProgressLoading(true);
    setLoading(true);
    var url_ = config.APIURL + "/order/getorderlist?limit="+globalLimit;
    // var data = { offset: 0, limit: globalLimit };
    axios
      .get(url_)
      .then((res) => {
        // console.log(res.data);
        setNextUrl(res.data.listinfo.NextUrl!=null?res.data.listinfo.NextUrl:"")
        setPreviousUrl(res.data.listinfo.PreviousUrl!=null?res.data.listinfo.PreviousUrl:"")
        setHasNextUrl(res.data.listinfo.isNext)
        setHasPreviousUrl(res.data.listinfo.isPrevious)
        var data = res.data.orderlist;
      

        var ddt = [];
       
        for (var x = 0; x < data.length; x++) {
          var cc = data[x];
          // console.log("---------------------------------");
          // console.log(
          //   cc.fulfillment_status == null
          //     ? "unfulfilled"
          //     : cc.fulfillment_status
          // );
          var c = {};
          c.id = cc.id;
          c.name_ = cc.name;
          c.updated_at = cc.processed_at;
          c.customerName =  cc.customer != null ? cc.customer.first_name + " " + cc.customer.last_name : '';
          c.total_price = cc.total_price;
          c.financial_status = cc.financial_status.toUpperCase();
          c.fulfillment_status =
            cc.fulfillment_status == null
              ? "Unfulfilled".toUpperCase()
              : cc.fulfillment_status;
          c.line_items = cc.line_items.length;
          c.deliveryMethod = cc.shipping_lines.length > 0 ? cc.shipping_lines[0].code : null ;
          c.tags = cc.tags;
          ddt.push(c);
        }
        // console.log("ddt");
        // console.log(ddt);
        setCustomerList(ddt);

        setProgressLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handlePrevList = async () => {
    if (hasPreviousUrl) {
      setProgressLoading(true);
    setLoading(true);
    var url_ = config.APIURL + "/order/getorderlist?limit="+globalLimit +"&pageUrl="+previousUrl;
    // var data = { offset: 0, limit: globalLimit };
    var res = await axios.get(url_);
      // .then((res) => 
      if(res.data){
        // console.log(res.data);
        setNextUrl(res.data.listinfo.NextUrl!=null?res.data.listinfo.NextUrl:"")
        setPreviousUrl(res.data.listinfo.PreviousUrl!=null?res.data.listinfo.PreviousUrl:"")
        setHasNextUrl(res.data.listinfo.isNext)
        setHasPreviousUrl(res.data.listinfo.isPrevious)
        var data = res.data.orderlist;
      

        var ddt = [];
       
        for (var x = 0; x < data.length; x++) {
          var cc = data[x];
          // console.log("---------------------------------");
          // console.log(
          //   cc.fulfillment_status == null
          //     ? "unfulfilled"
          //     : cc.fulfillment_status
          // );
          var c = {};
          c.id = cc.id;
          c.name_ = cc.name;
          c.updated_at = cc.processed_at;
          c.customerName = cc.customer != null ? cc.customer.first_name + " " + cc.customer.last_name : '';
          c.total_price = cc.total_price;
          c.financial_status = cc.financial_status;
          c.fulfillment_status =
            cc.fulfillment_status == null
              ? "unfulfilled"
              : cc.fulfillment_status;
          c.line_items = cc.line_items.length;
          c.deliveryMethod = cc.shipping_lines.length > 0 ? cc.shipping_lines[0].code : null ;
          c.tags = cc.tags;
          ddt.push(c);
        }
        // console.log("ddt");
        // console.log(ddt);
        setCustomerList(ddt);

        setProgressLoading(false);
      }
      else{
        setProgressLoading(false);
      }
      // )
      // .catch((error) => {
      //   console.log(error);
      // });
    }
  };

  const handleNextList = async () => {
    if (hasNextUrl) {
      setProgressLoading(true);
    setLoading(true);
    var url_ = config.APIURL + "/order/getorderlist?limit="+globalLimit +"&pageUrl="+nextUrl;
    // var data = { offset: 0, limit: globalLimit };
    var res = await axios.get(url_);
      // .then((res) => 
      if(res.data){
        console.log(res.data);
        setNextUrl(res.data.listinfo.NextUrl)
        setPreviousUrl(res.data.listinfo.PreviousUrl!=null?res.data.listinfo.PreviousUrl:"")
        setHasNextUrl(res.data.listinfo.isNext)
        setHasPreviousUrl(res.data.listinfo.isPrevious)
        var data = res.data.orderlist;
      

        var ddt = [];
       
        for (var x = 0; x < data.length; x++) {
          var cc = data[x];
          // console.log("---------------------------------");
          // console.log(
          //   cc.fulfillment_status == null
          //     ? "unfulfilled"
          //     : cc.fulfillment_status
          // );
          var c = {};
          c.id = cc.id;
          c.name_ = cc.name;
          c.updated_at = cc.processed_at;
          c.customerName = cc.customer != null ? cc.customer.first_name + " " + cc.customer.last_name : '';
          c.total_price = cc.total_price;
          c.financial_status = cc.financial_status;
          c.fulfillment_status =
            cc.fulfillment_status == null
              ? "unfulfilled"
              : cc.fulfillment_status;
          c.line_items = cc.line_items.length;
          c.deliveryMethod = cc.shipping_lines.length > 0 ? cc.shipping_lines[0].code : null ;
          c.tags = cc.tags;
          ddt.push(c);
        }
        // console.log("ddt");
        // console.log(ddt);
        setCustomerList(ddt);

        setProgressLoading(false);
      }
      else{
        setProgressLoading(false);
      }
      // )
      // .catch((error) => {
      //   console.log(error);
      // });
    }
  };

  const redirect = (id) => {
    window.location.href = "/create-special-customer-tiers";
  };
  const handleSearchClearButton = useCallback(() => {
    setcustomerSearchTextField("");
    // getAllCustomer();
    getAllOrder();
    setLoading(false);
  }, []);

  const handleSearch = useCallback(async (newValue) => {
    setcustomerSearchTextField(newValue);
    
  });

  const submitSearch = async () => {
    // console.log(customerSearchTextField)
    if(customerSearchTextField.includes('#')){
      // console.log(encodeURIComponent(customerSearchTextField));
    }
    // alert(customerSearchTextField)

    //#region search submit
    setProgressLoading(true);
    setLoading(true);
    var url_ = config.APIURL + "/order/getorderlist?limit=" + globalLimit + '&query=' + encodeURIComponent(customerSearchTextField);
    // var data = { offset: 0, limit: globalLimit };
    axios
      .get(url_)
      .then((res) => {
        // console.log(res.data);
        setNextUrl(res.data.listinfo.NextUrl!=null?res.data.listinfo.NextUrl:"")
        setPreviousUrl(res.data.listinfo.PreviousUrl!=null?res.data.listinfo.PreviousUrl:"")
        setHasNextUrl(res.data.listinfo.isNext)
        setHasPreviousUrl(res.data.listinfo.isPrevious)
        var data = res.data.orderlist;
      

        var ddt = [];
       
        for (var x = 0; x < data.length; x++) {
          var cc = data[x];
          // console.log("---------------------------------");
          // console.log(
          //   cc.fulfillment_status == null
          //     ? "unfulfilled"
          //     : cc.fulfillment_status
          // );
          var c = {};
          c.id = cc.id;
          c.name_ = cc.name;
          c.updated_at = cc.processed_at;
          c.customerName = cc.customer.first_name + " " + cc.customer.last_name;
          c.total_price = cc.total_price;
          c.financial_status = cc.financial_status;
          c.fulfillment_status =
            cc.fulfillment_status == null
              ? "unfulfilled"
              : cc.fulfillment_status;
          c.line_items = cc.line_items.length;
          c.deliveryMethod = cc.shipping_lines.length > 0 ? cc.shipping_lines[0].code : null ;
          c.tags = cc.tags;
          ddt.push(c);
        }
        // console.log("ddt");
        // console.log(ddt);
        setCustomerList(ddt);

        setProgressLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
    // var url_ = config.APIURL + "/tier/getCustomerList";
    // var data = { offset: 0, limit: globalLimit, query: newValue };
    // axios
    //   .post(url_, data)
    //   .then((res) => {
    //     console.log(res);
    //     var data = res.data.customer.docs;
    //     paginationObj.hasNextPage = res.data.customer.hasNextPage;
    //     paginationObj.hasPrevPage = res.data.customer.hasPrevPage;
    //     paginationObj.limit = res.data.customer.limit;
    //     paginationObj.nextPage = res.data.customer.nextPage;
    //     paginationObj.offset = res.data.customer.offset;
    //     paginationObj.page = res.data.customer.page;
    //     paginationObj.pagingCounter = res.data.customer.pagingCounter;
    //     paginationObj.prevPage = res.data.customer.prevPage;
    //     paginationObj.totalDocs = res.data.customer.totalDocs;
    //     paginationObj.totalPages = res.data.customer.totalPages;
    //     //setMasterTheme(res.data.response.masterThemeList);

    //     var ddt = [];
    //     // var dLength = data.length;
    //     // setPreviousUrl(data[0].cursor);
    //     // var last = dLength > 0 ? (dLength - 1) : 0;
    //     // setNextUrl(data[last].cursor)
    //     for (var x = 0; x < data.length; x++) {
    //       var cc = data[x];

    //       var c = {};
    //       c.displayName = cc.customer_name;
    //       c.email = cc.customer_email;
    //       c.id = cc.customer_id;
    //       ddt.push(c);
    //     }
    //     // console.log(ddt);
    //     setCustomerList(ddt);

    //     setProgressLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
      //#endregion

  }

  var handleKeyPress = (event) => {
    // console.log(event.key);
    if (event.key === "Enter") {
      // console.log("enter press here! ");
      submitSearch();
    }
  };

  return (
    <Frame>
      <Page title="Order List">
        {loadingMarkup}

        <Card>
          <Card.Section>
          <div class=""><div class="Polaris-Connected"><div class="Polaris-Connected__Item Polaris-Connected__Item--primary"><div class="Polaris-TextField Polaris-TextField--hasValue"><input onChange={(e) => handleSearch(e.target.value)} onKeyPress={(event) => handleKeyPress(event)} id="PolarisTextField1" placeholder="Search by #order name, customer name or customer email" autocomplete="off" class="Polaris-TextField__Input Polaris-TextField__Input--hasClearButton" aria-labelledby="PolarisTextField1Label" aria-invalid="false" aria-multiline="false" value={customerSearchTextField}></input><button type="button" class="Polaris-TextField__ClearButton" onClick={handleSearchClearButton}><span class="Polaris-VisuallyHidden">Clear</span><span class="Polaris-Icon Polaris-Icon--colorInkLightest Polaris-Icon--isColored"><svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M14.242 12.829l-1.414 1.414L10 11.413l-2.828 2.83-1.414-1.414 2.828-2.83-2.828-2.827 1.414-1.414L10 8.586l2.828-2.828 1.414 1.414L11.414 10l2.828 2.829zM10 1.999A8 8 0 1 0 10 18a8 8 0 0 0 0-16z" fill-rule="evenodd"></path></svg></span></button><div class="Polaris-TextField__Backdrop"></div></div></div><div class="Polaris-Connected__Item Polaris-Connected__Item--connection"><button  onClick={submitSearch} type="button" class="Polaris-Button"><span class="Polaris-Button__Content"><span class="Polaris-Button__Text">Search</span></span></button></div></div></div>
            {/* <TextField
              placeholder="Search by #order name, customer name or customer email"
              value={customerSearchTextField}
              onChange={handleSearch}
              autoComplete="off"
              clearButton
              onClearButtonClick={handleSearchClearButton}
              connectedRight={
                <Button onClick={submitSearch}>Search</Button>
              }
              key={(event) => handleKeyPress(event)}
            ></TextField> */}
          </Card.Section>
          <div className="Polaris-ResourceItem__Container">
            <table style={{ width: "100%" }}>
              <thead className="table-thead">
                <tr>
                  <td className="orderList__orderColumn">Order</td>
                  <td className="orderList__dateColumn">Date</td>
                  <td className="orderList__customerColumn">Customer</td>
                  <td className="orderList__totalColumn">Total</td>
                  <td className="orderList__paymentColumn">Payment</td>
                  <td className="orderList__fulfillmentColumn">Fulfillment</td>
                  <td className="orderList__itemsColumn">Items</td>
                  <td className="orderList__deliveryColumn">Delivery method</td>
                  <td className="orderList__tagsColumn">Tags</td>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          {progressLoading ? 
          <Stack distribution="center"><Spinner accessibilityLabel="Spinner example" size="large" color="highlight" /> </Stack>
          :
          <ResourceList
            resourceName={{ singular: "customer", plural: "customers" }}
            items={customerList}
            renderItem={(item) => {
              const {
                id,
                name_,
                updated_at,
                customerName,
                total_price,
                financial_status,
                fulfillment_status,
                line_items,
                deliveryMethod,
                tags,
              } = item;
              //const media = <Avatar customer size="medium" name={displayName} />;

              return (
                <ResourceItem
                  id={name_}
                  url={'/orderdetail/' + id }
                  // media={media}
                  //accessibilityLabel={`View details for ${displayName}`}
                >
                  {/* <Stack alignment="center"> */}
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="orderList__orderColumn">{name_}</td>
                        <td className="orderList__dateColumn">
                          <Moment local format="DD/MM/YYYY HH:mm">
                            {updated_at}
                          </Moment>
                        </td>
                        <td className="orderList__customerColumn">
                          {customerName}
                        </td>
                        <td className="orderList__totalColumn">
                          {total_price}
                        </td>
                        <td className="orderList__paymentColumn">
                          {financial_status}
                        </td>
                        <td className="orderList__fulfillmentColumn">
                          {fulfillment_status}
                        </td>
                        <td className="orderList__itemsColumn">{line_items}</td>
                        <td className="orderList__deliveryColumn">
                          {deliveryMethod}
                        </td>
                        <td className="orderList__tagsColumn">{tags}</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* </Stack> */}
                </ResourceItem>
              );
            }}
            // selectable
          />
          }
          <Card.Section>
            <Stack spacing="loose" alignment="center" distribution="center">
              {hasPreviousUrl== true ? (
                <Button onClick={handlePrevList}>Previous</Button>
              ) : (
                <Button onClick={handlePrevList} disabled>
                  Previous
                </Button>
              )}
              <span>{paginationObj.page}</span>
              {hasNextUrl== true ? (
                <Button onClick={handleNextList}>Next</Button>
              ) : (
                <Button onClick={handleNextList} disabled>
                  Next
                </Button>
              )}
            </Stack>
          </Card.Section>
        </Card>

        {toastMarkup}
      </Page>
    </Frame>
  );
};

export default OrderListComponent;
