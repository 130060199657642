import React, { Component } from 'react';
import {SkeletonBodyText,Badge,Button, Banner,Link} from '@shopify/polaris';
import axios from 'axios';

export default class ThemeVersionInfo extends Component {

    constructor(props) {
        super(props);
        this.state={
            themeInfo:{},
            loading:true,
            updateBtnLoading:false,
          }
     
        this._isMounted = false;
        this.isNewerVersion =(oldVer, newVer)=> {
        
         // console.log(oldVer+'--------checking version  '+newVer)
            const oldParts = oldVer.split('.')
            const newParts = newVer.split('.')
            for (var i = 0; i < newParts.length; i++) {
              const a = parseInt(newParts[i]) || 0
              const b = parseInt(oldParts[i]) || 0
              if (a > b){
            //    console.log(a+ '  > '+b+'   true')
                return true}
              if (a < b){ 
                
             //   console.log(a+ '  < '+b+'   false')
                return false}
            }
            return false
          }


    }

    componentDidMount() {
      this._isMounted = true;
      this._isMounted &&   this.getThemeInfo();
      }
      componentWillUnmount() {
        this._isMounted = false;
     }
    
     async getThemeInfo() {
       this.setState({loading:true});
       var api = window.$apiUrl;
        axios.get(api+'/themes/getlivethemeinfo/'+window.$shopId+'/'+this.props.obj.id)
       .then(res => {
        this._isMounted &&  this.setState({
                themeInfo: res.data.response,
                loading:false
            }); 
          })
          .catch((error) => {
            this.setState({loading:false});
            console.log(error);
          })
      }
     
   
   
    

    render() {
       return(
            <div>
     
     { 
       this.state.loading===true?
        ( 
          
        <div className="Polaris-Card__Section" style={{paddingBottom: "0.3rem"}}>
        {/* <Spinner accessibilityLabel="Spinner example" size="large" color="teal" /> */}
       
        <SkeletonBodyText />
        </div>
      ):(
      <div>
        <div className="Polaris-Card__Header">
        
          <Banner title={this.state.themeInfo.name} status={this.props.obj.theme_type==this.props.planName || this.props.obj.theme_type=="free"?"default":"critical" }>
         { this.props.obj.theme_type==this.props.planName || this.props.obj.theme_type=="free"?null:(<p>This is paid theme, Please subscribe to use this theme </p>)}
         <Link external={true}  url={"https://"+window.$shopUrl+"/admin/themes/"+this.props.obj.id+"/editor"}>Customize</Link>
          </Banner>


      </div>
    

    {  this.props.masterTheme.latest===false?
        (
       <div>
      
  <div className="Polaris-Card__Section">
  <div className="Polaris-Stack__Item">
        <h2 className="Polaris-Heading">Theme Version 
          <Badge status="critical">
          {this.props.masterTheme.version}
          </Badge>
          <Badge>{ this.state.themeInfo.role=="unpublished"?"Unpublished":"Published"}  </Badge>
           </h2>
      </div>
  </div>
 
  <div className="Polaris-Card__Section">
  <div className="Polaris-Stack Polaris-Stack--distributionTrailing Polaris-Stack--alignmentFill">
    <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
    <h2 className="Polaris-Heading">Latest Version
          <Badge status="attention">
          {typeof this.props.latestTheme === "undefined"?this.props.masterTheme.version:this.props.latestTheme.version}
          </Badge>
    </h2>
    </div>
    {typeof this.props.latestTheme !== "undefined"?
    ( <div className="Polaris-Stack__Item">
    <Button disabled={this.props.updateBtnLoading || this.props.obj.update_running_status !="idol"?true:false} style={{marginLeft:"5%"}}   primary onClick={()=>this.props.onThemeUpdateClick(this.props.obj,this.props.latestTheme)} >{this.props.updateBtnLoading ||this.props.obj.update_running_status=="running"?"Update in progress":"Update" }</Button>
     </div>
):null
  }
   
        {
      typeof this.props.latestTheme !== "undefined" && this.props.obj.update_running_status=="completed"?(
            <div className="Polaris-Stack__Item">
            <Button  style={{marginLeft:"5%"}}   onClick={()=>this.props.openViewChangeModal(this.props.obj.theme_diffrence_files,this.props.obj.id,this.props.obj._id,this.props.obj.upgraded_theme_id)} >View Changes</Button>
    
            </div>
          ):null


      }

  </div>
  </div> 
       
       </div>
         ):
         ( 
         
         <div>
        <div className="Polaris-Card__Section">
        <div className="Polaris-Stack__Item">
              <h2 className="Polaris-Heading">Theme Version
                <Badge status="attention">
                {this.props.masterTheme.version}
                </Badge>
                <Badge status="success">Latest version Installed</Badge>
                <Badge status={ this.state.themeInfo.role=="unpublished"?"":"success"}> { this.state.themeInfo.role=="unpublished"?"Unpublished":"Published"} </Badge>
                 </h2>
            </div>
        </div>
        
       
       </div>)
    }
        </div>
        
      )
       }
       
       
        </div>
        )

  
      }




}