import React, { useEffect, useState, useCallback } from "react";
import { ResourceList, Page, Avatar, ResourceItem, FooterHelp, Loading, Heading, Toast, Frame, Stack, Button, Card, TextStyle, PageActions, Layout, Link, TextField } from '@shopify/polaris';
import axios from 'axios';
import config from "../config.json";
import { PageItem } from "react-bootstrap";
const CustomerList = (props) => {
  const [customerList, setCustomerList] = useState('')
  const [nextUrl, setNextUrl] = useState('')
  const [previousUrl, setPreviousUrl] = useState('')

  const [progressLoading, setProgressLoading] = useState(false);
  const loadingMarkup = progressLoading && <Loading />;
  const [customerSearchTextField, setcustomerSearchTextField] = useState();
  const [errorActive, setErrorActive] = useState({ activate: false, errorMessage: '' });
  const [loading, setLoading] = useState(false)
  let globalLimit = 50;

  const toastMarkup = errorActive.activate ? (
    <Frame> <Toast content={errorActive.errorMessage} onDismiss={() => setErrorActive({ activate: false, errorMessage: '' })} /></Frame>
  ) : null;

  useEffect(
    () => {
      getAllCustomer();


    }, [props])

  const [paginationObj, setPaginationObj] = useState({});

  const getAllCustomer = () => {
    setProgressLoading(true);
    setLoading(true);
    var url_ = config.APIURL + '/tier/getCustomerList';
    var data = { offset: 0, limit: globalLimit };
    axios.post(url_, data)
      .then(res => {
        console.log(res)
        var data = res.data.customer.docs;
        paginationObj.hasNextPage = res.data.customer.hasNextPage;
        paginationObj.hasPrevPage = res.data.customer.hasPrevPage;
        paginationObj.limit = res.data.customer.limit;
        paginationObj.nextPage = res.data.customer.nextPage;
        paginationObj.offset = res.data.customer.offset;
        paginationObj.page = res.data.customer.page;
        paginationObj.pagingCounter = res.data.customer.pagingCounter;
        paginationObj.prevPage = res.data.customer.prevPage;
        paginationObj.totalDocs = res.data.customer.totalDocs;
        paginationObj.totalPages = res.data.customer.totalPages;
        //setMasterTheme(res.data.response.masterThemeList);

        var ddt = [];
        // var dLength = data.length;
        // setPreviousUrl(data[0].cursor);
        // var last = dLength > 0 ? (dLength - 1) : 0;
        // setNextUrl(data[last].cursor)
        for (var x = 0; x < data.length; x++) {
          var cc = data[x];

          var c = {};
          c.displayName = cc.customer_name;
          c.email = cc.customer_email;
          c.id = cc.customer_id;
          ddt.push(c);
        }
        // console.log(ddt);
        setCustomerList(ddt);

        setProgressLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const handlePrevList = () => {
    if (paginationObj.hasPrevPage) {
    setProgressLoading(true);
    setLoading(true);
      var url_ = config.APIURL + '/tier/getCustomerList';
      var data = { offset: (paginationObj.offset - paginationObj.limit), limit: globalLimit
      , query:customerSearchTextField };
      axios.post(url_, data)
        .then(res => {
          console.log(res)
          var data = res.data.customer.docs;
          paginationObj.hasNextPage = res.data.customer.hasNextPage;
          paginationObj.hasPrevPage = res.data.customer.hasPrevPage;
          paginationObj.limit = res.data.customer.limit;
          paginationObj.nextPage = res.data.customer.nextPage;
          paginationObj.offset = res.data.customer.offset;
          paginationObj.page = res.data.customer.page;
          paginationObj.pagingCounter = res.data.customer.pagingCounter;
          paginationObj.prevPage = res.data.customer.prevPage;
          paginationObj.totalDocs = res.data.customer.totalDocs;
          paginationObj.totalPages = res.data.customer.totalPages;

          var ddt = [];
          for (var x = 0; x < data.length; x++) {
            var cc = data[x];
            var c = {};
            c.displayName = cc.customer_name;
            c.email = cc.customer_email;
            c.id = cc.customer_id;
            ddt.push(c);
          }
          // console.log(ddt);
          setCustomerList(ddt);

          setProgressLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  const handleNextList = () => {
    if (paginationObj.hasNextPage) {
    setProgressLoading(true);
    setLoading(true);
      var url_ = config.APIURL + '/tier/getCustomerList';
      var data = { offset: (paginationObj.offset + paginationObj.limit), limit: globalLimit,
        query:customerSearchTextField
       };
      axios.post(url_, data)
        .then(res => {
          console.log(res)
          var data = res.data.customer.docs;
          paginationObj.hasNextPage = res.data.customer.hasNextPage;
          paginationObj.hasPrevPage = res.data.customer.hasPrevPage;
          paginationObj.limit = res.data.customer.limit;
          paginationObj.nextPage = res.data.customer.nextPage;
          paginationObj.offset = res.data.customer.offset;
          paginationObj.page = res.data.customer.page;
          paginationObj.pagingCounter = res.data.customer.pagingCounter;
          paginationObj.prevPage = res.data.customer.prevPage;
          paginationObj.totalDocs = res.data.customer.totalDocs;
          paginationObj.totalPages = res.data.customer.totalPages;

          var ddt = [];
          for (var x = 0; x < data.length; x++) {
            var cc = data[x];
            var c = {};
            c.displayName = cc.customer_name;
            c.email = cc.customer_email;
            c.id = cc.customer_id;
            ddt.push(c);
          }
          // console.log(ddt);
          setCustomerList(ddt);

          setProgressLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  const redirect = (id) => {
    window.location.href = "/create-special-customer-tiers"
  }
  const uploadCvv = (id) => {
    window.location.href = "/uploadTiers"
  }

  const deleteCustomerTier = (e,id) => {
    alert(id)
    e.preventDefault()
    window.location.href = "/create-special-customer-tiers"
  }


  const handleSearchClearButton = useCallback( () => {
    setcustomerSearchTextField("")
    getAllCustomer();
    setLoading(false); 
  }, [] );

  const handleSearch = useCallback(
    async (newValue) => {
      
      setcustomerSearchTextField(newValue);
      var url_ = config.APIURL + '/tier/getCustomerList';
      var data = { offset: 0, limit: globalLimit ,query:newValue};
      axios.post(url_, data)
      .then(res => {
        console.log(res)
        var data = res.data.customer.docs;
        paginationObj.hasNextPage = res.data.customer.hasNextPage;
        paginationObj.hasPrevPage = res.data.customer.hasPrevPage;
        paginationObj.limit = res.data.customer.limit;
        paginationObj.nextPage = res.data.customer.nextPage;
        paginationObj.offset = res.data.customer.offset;
        paginationObj.page = res.data.customer.page;
        paginationObj.pagingCounter = res.data.customer.pagingCounter;
        paginationObj.prevPage = res.data.customer.prevPage;
        paginationObj.totalDocs = res.data.customer.totalDocs;
        paginationObj.totalPages = res.data.customer.totalPages;
        //setMasterTheme(res.data.response.masterThemeList);

        var ddt = [];
        // var dLength = data.length;
        // setPreviousUrl(data[0].cursor);
        // var last = dLength > 0 ? (dLength - 1) : 0;
        // setNextUrl(data[last].cursor)
        for (var x = 0; x < data.length; x++) {
          var cc = data[x];

          var c = {};
          c.displayName = cc.customer_name;
          c.email = cc.customer_email;
          c.id = cc.customer_id;
          ddt.push(c);
        }
        // console.log(ddt);
        setCustomerList(ddt);

        setProgressLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
    }
    
      );
  return (
    <Frame>
      <Page 
       primaryAction={
        // {
      //   content: "Export Tiers",
      //   onClick: exportTiers, //create button
      // }ExportTiers
      // <a href={config.APIURL + '/export/ExportCustomerTiers'}>Export Tier</a>
      // <button><a href={config.APIURL + '/export/ExportCustomerTiers'}>Export Tier</a></button>

<button className="Polaris-Button Polaris-Button--primary" type="button"><span className="Polaris-Button__Content">


<span className="Polaris-Button__Text">
  
<a href={config.APIURL + '/export/ExportCustomerTiers'}>Export Tiers</a>
</span>


</span></button>

      
    }
      title="Customer List">
        {loadingMarkup}

        <PageActions
          // primaryAction={{
          //   content: 'Upload Tiers',
          //   onClick: uploadCvv //create button
          // }}
          primaryAction={{
            content: 'Create Tiers',
            primary:true,
            onClick: redirect //create button
          }}
          
        />

        <Card>
          
          <Card.Section>
            <TextField placeholder="Search Customer" 
            value={customerSearchTextField}
            onChange={handleSearch}
            autoComplete="off"
            clearButton 
            onClearButtonClick={handleSearchClearButton}>

            </TextField>
          </Card.Section>

          <ResourceList
            resourceName={{ singular: 'customer', plural: 'customers' }}
            items={customerList}
            renderItem={(item) => {

              const { id, email, displayName } = item;
              const media = <Avatar customer size="medium" name={displayName} />;

              return (
                <ResourceItem

                  id={id}
                  url={'/customer/' + id + '/' + displayName + '/' + email}
                  media={media}
                  accessibilityLabel={`View details for ${displayName}`}
                >

                  <Stack alignment="center">
                  <Stack.Item fill>
                    <Heading>{displayName}</Heading>
                    <div>{email}</div>
                    </Stack.Item>
                    {/* <Stack.Item>
                      <Button onClick={(e) => {redirect(e,id)}} primary>Create</Button>
                    </Stack.Item> */}
                    {/* <Stack.Item>
                      <Button onClick={(e) => {deleteCustomerTier(e,id)}} destructive>Delete</Button>
                    </Stack.Item> */}
                  </Stack>


                </ResourceItem>
              );
            }}
            // selectable
          />

          <Card.Section>
            <Stack spacing="loose" alignment="center" distribution="center">
            {paginationObj.hasPrevPage == true ?
              <Button onClick={handlePrevList}>Previous</Button>
            : <Button onClick={handlePrevList} disabled>Previous</Button>
            }
              <span>{paginationObj.page}</span>
            {paginationObj.hasNextPage == true ?
              <Button onClick={handleNextList}>Next</Button>
            : <Button onClick={handleNextList} disabled>Next</Button>
            }
            </Stack>
          </Card.Section>
        </Card>

        {toastMarkup}

      </Page>
    </Frame>
  );

}

export default CustomerList;