import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import translations from '@shopify/polaris/locales/en.json';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AppProvider } from '@shopify/polaris';
import "@shopify/polaris/styles.css";

//window.$apiUrl="https://2440d89e.ngrok.io"  //globally declared api url

ReactDOM.render(
  <BrowserRouter>
  <AppProvider i18n={translations}>
    <App />
    </AppProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
