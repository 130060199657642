import React, { useState,useCallback ,useEffect} from 'react';
import config from "../config.json"; 
import axios from 'axios';
import {TextField,Toast,Frame} from "@shopify/polaris";
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';

const Login = (props) =>  {
  const [_cookie, set_cookie] = useState(false);
  const [username, setUserNameValue] = useState('');
  const [password, setPasswordValue] = useState('');

  const [userNameError, setuserNameError] = useState('');
  const [passwordError, setpasswordError] = useState('');
  // handle button click of login form
  const cookie_key = 'namedOFCookie';
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Invalid User or Password" error onDismiss={toggleActive} />
  ) : null;

  useEffect(() => {
   
  }, [props]);

  const onChangeName= (async (newValue) => {
  setUserNameValue(newValue)
  setuserNameError()
  })
  const onChangePassword=(async (newValue) => {
    setPasswordValue(newValue)
    setpasswordError()
    })
  const ValidateUser= () =>{

    localStorage.removeItem("od_ulbdckupfg")
  localStorage.removeItem("us_ulbdckupfg")
    bake_cookie(cookie_key, 'wahaj');
  if(username==''||username==null ){
  setuserNameError('User name is required');
  }
  if(password==''||password==null ){
    setpasswordError('password is required');
    }


    if((username!=''&&username!=null )&&(password!=''&&password!=null )){
    axios.get(config.APIURL + "/AuthenticateUser?userName=" + username + "&password=" + password)
    .then((res) => {  if(res.data.status!='Success'){

      setActive(true);
    }else{
      if(res.data.user==true){
    
        localStorage.setItem('od_ulbdckupfg', true);
        localStorage.setItem('us_ulbdckupfg', true);
         window.location = '/customerlist';
      }
      else{ 
        localStorage.setItem('us_ulbdckupfg', true);
        window.location = '/orderlist';
      }
    
    

    }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  //  if(userNameRequired==true && passwordRequired==true)
  //  {
  //    window.location = '/customerlist';
  //   // axios.post(config.APIURL+'/admin/login',{username:username,password:password})
  //   //    .then(res => {
  //   //      console.log(res.data);
  //   //      if(res.data.status=="success")
  //   //      {
  //   //         props.history.push('/customerlist');

  //   //      }
  //   //    })
  //   //    .catch((error) => {
  //   //      console.log(error);
  //   //    });
  //   }else{
  //     setActive(true);
  //   }

}

console.log("cookie")
console.log(read_cookie(cookie_key))
  return (
    <div className="Polaris-EmptyState Polaris-EmptyState--withinPage">
    <div className="Polaris-EmptyState__Section">
      <div className="Polaris-EmptyState__DetailsContainer">
        <div className="Polaris-EmptyState__Details">
                 <div className="Polaris-Layout__AnnotationContent">
                   <form id="loginForm" action="/admin/login" method="POST">
                      <div className="Polaris-Card">
                        <div className="Polaris-Card__Section">
                          <div className="Polaris-FormLayout">
   <div className="Polaris-FormLayout__Item">
                            
                            </div>

                            <div className="Polaris-FormLayout__Item">
                             
<TextField
      label="User Name"
      value={username}
      onChange={onChangeName}
      error={userNameError}
      autoComplete={false}
     
    />

                            </div>
                            <div className="Polaris-FormLayout__Item">
                            
                            <TextField
      label="Password"
      value={password}
      onChange={onChangePassword}
      error={passwordError}
      type="password"
    />
                            </div>
                              <div className="Polaris-FormLayout__Item">
                              <div className="">
                               
                                <div className="Polaris-Connected">
                                  <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                    <div className="Polaris-TextField Polaris-TextField--hasValue">
                               <div style={{"--top-bar-background":"#00848e", "--top-bar-background-lighter":"#1d9ba4", "--top-bar-color":"#f9fafb", "--p-frame-offset":"0px"}}><button type="button" onClick={ValidateUser} className="Polaris-Button Polaris-Button--primary"><span className="Polaris-Button__Content"><span className="Polaris-Button__Text">Login</span></span></button></div>
                                     
                                   
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </form>

                    </div>
           
        
         
        </div>
      </div>
      <div className="Polaris-EmptyState__ImageContainer"><img src="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg" role="presentation" alt="" className="Polaris-EmptyState__Image" /></div>
    </div>
  <Frame>
    {toastMarkup}
    </Frame>
  </div>
 






  );

}
 

export default Login;