import React, { useEffect, useState, useCallback } from "react";
import {
  ResourceList,
  SettingToggle,
  Page,
  Avatar,
  ResourceItem,
  FooterHelp,
  Loading,
  Heading,
  Toast,
  Frame,
  Stack,
  Button,
  DisplayText,
  Form,
  FormLayout,
  Card,
  Spinner,
  RadioButton,
  TextStyle,
  TextField,
  PageActions,
  Layout,
  TextContainer,
  Modal,
  Link
} from '@shopify/polaris';
import "./css/productSearchHTML.css";
import config from "../config.json";
import axios from 'axios';
import Moment from 'react-moment';

const CustomerComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  var [divValue, setDivValue] = useState([]);
  const [holdPage, setHoldPage] = useState(false);
  const [customerId, setCustomerId] = useState(props.match.params.id)
  const [customerName, setCustomerName] = useState(props.match.params.Name)
  const [customerEmail, setCustomerEmail] = useState(props.match.params.email)
  const [progressLoading, setProgressLoading] = useState(false);
  const loadingMarkup = progressLoading && <Loading />;
  const [productSearchTextField, setproductSearchTextField] = useState(); //search const
  const [successActive, setSuccessActive] = useState(false); //success Toast
  const [successToggleMesssage, setSuccessToggleMesssage] = useState("");
  const toggleSuccessActive = useCallback(() => setSuccessActive((successActive) => !successActive), []);
  const successToast = successActive ? (
    <Toast content={successToggleMesssage} onDismiss={toggleSuccessActive} />
  ) : null;

  const [paginationObj, setPaginationObj] = useState({
    globalLimit: 50, globalOffset: 0, pageCounter: 1, remainingPages: 0
  });
  useEffect(
    () => {
      setproductSearchTextField("");
      getCustomerById(customerId);
    }, [props])

  const getCustomerById = (customerId) => {
    paginationObj.pageCounter = 1;
    setProgressLoading(true);
    setLoading(true);
    let url = config.APIURL + '/tier/getTierListByCustomer';
    let data = { id: customerId, offset: 0, limit: paginationObj.globalLimit };
    axios.post(url, data)
      .then(res => {
        console.log(res.data)
        if(res.data.tierList.length > 0){
          paginationObj.remainingPages = res.data.tierList[0].count - paginationObj.globalLimit;
          var Tiers = res.data.tierList[0].TierObject;
          console.log(Tiers);
          setTiers(Tiers);
          setProgressLoading(false);
          setHoldPage(false);
        }
        else{
          window.location.href = "/customerlist"
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  //Modal
  var [TierObject, setTierObject] = useState({});
  const [activeToggle, setActiveToggle] = useState(false); //Error Toast Polaris
  const [toggleMesssage, setToggleMesssage] = useState("");
  const toggleMessageChange = useCallback((value) => setToggleMesssage(value), []);
  const toggleActive = useCallback(() => setActiveToggle((activeToggle) => !activeToggle), []);
  const toastValidationError = activeToggle ? (<Toast content={toggleMesssage} error onDismiss={toggleActive} duration={4500} />) : null;
  const [textFieldValue, setTextFieldValue] = useState();
  const handleTextField = useCallback((newValue) => setTextFieldValue(newValue), []);
  const [submitButtonText, setSaveTier] = useState('Update Tier');
  const [activeModal, setActiveModal] = useState(false);

  //Enable Disable Tier
  const enableDisableTier = (entity_id, entity_title, entity_type, discount_type, created_date, tier_status, tier_value) => {
    setProgressLoading(true);
    setHoldPage(true);
    if (tier_status) { tier_status = false } else { tier_status = true }
    if (tier_value.indexOf('true') !== -1) {
      tier_value = tier_value.replace('true', 'false');
    } else {
      tier_value = tier_value.replace('false', 'true');
    }
    var url = config.APIURL + '/tier/enabledisableTier'; //Enable Or Disable the tier
    var data = { customerID: customerId, entity_id: entity_id, tier_value: tier_value, tier_status: tier_status }
    axios.post(url, data)
      .then(async (res) => {
        if (tier_status) { setSuccessToggleMesssage("Tier Enabled Successfully"); } //Success Toast
        else { setSuccessToggleMesssage("Tier Disabled Successfully"); }
        //Call List again on the existing page  
        var _url_ = config.APIURL + '/tier/getTierListByCustomer';
        var datatosend = { id: customerId, offset: paginationObj.globalOffset, limit: paginationObj.globalLimit };
        var refresh_ = await axios.post(_url_, datatosend);
        var Tiers = refresh_.data.tierList[0].TierObject;
        setTiers(Tiers);
        //Call List again on the existing page
        setProgressLoading(false);
        setHoldPage(false);
        // getCustomerById(customerId);
      })
      .catch((error) => {
        // console.log(error);
      })
  }

  //Modal Trigger start -
  const [obj, setObj] = useState([]);
 // let [divValue, setDivValue] = useState([]);
  const handleModalChange = (entity_id, entity_title, entity_type, discount_type, created_date, tier_status, tier_value) => {
    setSaveTier("Update Tier");
    TierObject.entity_id = entity_id; TierObject.entity_title = entity_title; TierObject.entity_type = entity_type; TierObject.discount_type = discount_type;
    TierObject.created_date = created_date; TierObject.tier_value = tier_value; TierObject.tier_status = tier_status; TierObject.customerId = customerId;
    var splittedTier = [];
    try {
      if (splittedTier != null) {
        splittedTier = tier_value.split(',');
      }
    } catch (e) { console.log(e); }
    if (splittedTier != null) {
      divValue.length = 0;
      setDivValue([]);
     
      //Assign Modal item
      if (discount_type == 'percentage') { setradioPercentage(true); setradioAmount(false); setradioDiscountedPrice(false) }
      else if (discount_type == 'fixed') { setradioPercentage(false); setradioAmount(true); setradioDiscountedPrice(false) }
      else { setradioPercentage(false); setradioAmount(false); setradioDiscountedPrice(true) } //Assign radio button value
      // setDivValue([]);
      for (var i = 6; i < splittedTier.length; i++) {
        divValue.push({1:1});
     
        obj[i - 6] = {
          MinQty: splittedTier[i].split('-')[0],
          MaxQty: splittedTier[i].split('=')[0].split('-')[1],
          Value: splittedTier[i].split('=')[1]
        }
      }
      setDivValue(divValue)
    }
    setActiveModal(!activeModal)
  };

  const handleplus = () => { //add textboxes row
    if (divValue.length >= 1) {
   setDivValue((oldArray) => [...oldArray, {1:1}]);
    };
  };

  const handleMinus = () => { //remove textboxes row
    if (divValue.length > 1) {
      var d =divValue.length - 1
      var newVal = [];
     for(var i=0;i<d;i++){
    newVal.push(divValue[i]);
     }
    setDivValue(newVal)
    }
  };

  //Modal Trigger end -
  const activatorModal = <Button onClick={handleModalChange}>Open</Button>;
  const [radioPercentage, setradioPercentage] = useState(true);
  const [radioAmount, setradioAmount] = useState(false);
  const [radioDiscountedPrice, setradioDiscountedPrice] = useState(false);
  const handleRadio = useCallback((_checked, newValue) => {
    switch (newValue) {
      case "r_percentage": setradioPercentage(true); setradioAmount(false); setradioDiscountedPrice(false);
        break;
      case "r_amount": setradioPercentage(false); setradioAmount(true); setradioDiscountedPrice(false);
        break;
      case "r_discount": setradioPercentage(false); setradioAmount(false); setradioDiscountedPrice(true);
        break;
      default: setradioPercentage(true); setradioAmount(false); setradioDiscountedPrice(false);
    }
  }, []);
  //Modal
  const handlePrevList = () => { //pagination prev button
    if (paginationObj.pageCounter > 1) {
      setHoldPage(true);
      setProgressLoading(true);
      setLoading(true);
      let url = config.APIURL + '/tier/getTierListByCustomer';
      let data = { id: customerId, offset: (((paginationObj.pageCounter - 1) - 1) * paginationObj.globalLimit), limit: paginationObj.globalLimit, query:productSearchTextField };
      axios.post(url, data)
        .then(res => {
          // console.log(res.data)
          paginationObj.remainingPages = paginationObj.remainingPages + paginationObj.globalLimit;
          paginationObj.globalOffset = paginationObj.globalOffset - paginationObj.globalLimit;
          var Tiers = res.data.tierList[0].TierObject;
          // console.log(Tiers);

          setTiers(Tiers);

          setProgressLoading(false);
          setHoldPage(false);
        })
        .catch((error) => {
          console.log(error);
        })
      paginationObj.pageCounter--;
    }
  }

  const handleNextList = () => { //pagination next button
    setHoldPage(true);
    setProgressLoading(true);
    setLoading(true);
    let url = config.APIURL + '/tier/getTierListByCustomer';
    let data = { id: customerId, offset: (((paginationObj.pageCounter + 1) - 1) * paginationObj.globalLimit), limit: paginationObj.globalLimit, query:productSearchTextField };
    axios.post(url, data)
      .then(res => {
        if (paginationObj.remainingPages > 0) {
          paginationObj.remainingPages = paginationObj.remainingPages - paginationObj.globalLimit;
          paginationObj.limit = res.data.tierList[0].count;
          paginationObj.globalOffset = paginationObj.globalOffset + paginationObj.globalLimit;
          console.log(res.data)
          var Tiers = res.data.tierList[0].TierObject;
          console.log(Tiers);

          setTiers(Tiers);

          setProgressLoading(false);
          setHoldPage(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
    paginationObj.pageCounter++;
  }

  const ThemeSubmit = async () => { //Theme Installation
    setSaveTier("Updating..."); //set submit button loading
    setHoldPage(false);
    var reqFields = [];
    var conditionMaxbyMinCheck = [],
      conditionMinbyAboveMaxCheck = [],
      conditionPercentageAmountCheck = [],
      conditionDiscountAmountCheck = [];
    var conditionLastMaxCheck = false;
    var tier_value = ""; // tier value string

    for (var i = 0; i < divValue.length; i++) {
      var allMinValues = Number(
        document.getElementById("txtRow" + i + "Col1").value
      );
      var allMaxValues = Number(
        document.getElementById("txtRow" + i + "Col2").value
      );
      var allDiscountValues = Number(
        document.getElementById("txtRow" + i + "Col3").value
      );
      var allMaxValuesOfAboveRow = 0,
        allDiscountValuesOfAboveRow = 0;
      if (i == (divValue.length - 1)) {
        tier_value =
          tier_value + allMinValues + "-" + "max" + "=" + allDiscountValues;
      } else {
        tier_value = tier_value + allMinValues + "-" + allMaxValues + "=" + allDiscountValues + ",";
      }

      //FieldsInput
      if (i > 0) {
        var minus = i - 1;
        allMaxValuesOfAboveRow = Number(
          document.getElementById("txtRow" + minus + "Col2").value
        );
        allDiscountValuesOfAboveRow = Number(
          document.getElementById("txtRow" + minus + "Col3").value
        );
      }
      //FieldsRequiredValidationCheck
      if (
        allMinValues == null ||allMinValues == "" ||allDiscountValues == null ||allDiscountValues == "" || (allMaxValues == null && i != (divValue.length - 1)) || (allMaxValues == "" && i != (divValue.length - 1))) {
        reqFields.push(false);
      } else {
        reqFields.push(true);
      }
      //conditionMaxbyMinCheck
      if (i == (divValue.length - 1) || allMaxValues >= allMinValues) {
        if (i == (divValue.length - 1)) {
          if (allMaxValues == null || allMaxValues == "") {
            conditionMaxbyMinCheck.push(true);
          }
          //  else {
          //   conditionMaxbyMinCheck.push(false);
          // }
        } else {
          conditionMaxbyMinCheck.push(true);
        }
      } else {
        conditionMaxbyMinCheck.push(false);
      }
      //conditionDiscountAmountCheck
      if (i > 0) {
        if (radioDiscountedPrice) {
          if (allDiscountValues <= allDiscountValuesOfAboveRow) {
            conditionDiscountAmountCheck.push(true);
          } else {
            conditionDiscountAmountCheck.push(false);
          }
        } else {
          if (allDiscountValues >= allDiscountValuesOfAboveRow) {
            conditionDiscountAmountCheck.push(true);
          } else {
            conditionDiscountAmountCheck.push(false);
          }
        }
      }
      //ValidationMinToPreMaxValueCheck
      if (i > 0) {
        if (allMinValues >= allMaxValuesOfAboveRow) {
          conditionMinbyAboveMaxCheck.push(true);
        } else {
          conditionMinbyAboveMaxCheck.push(false);
        }
      }
      //validateMaxTierEmptyCheck
      if (i == (divValue.length - 1)) {
        if (allMaxValues != null && allMaxValues != "") {
          conditionLastMaxCheck = true;
        }
      }
    }
    //AllAlerts
    if (reqFields.includes(false)) {
      //1 Condititon field required Check
      toggleMessageChange("Fields Required");
      setActiveToggle(true);
    } else if (conditionMaxbyMinCheck.includes(false)) {
      //2 MaxMIn Condititon Check
      toggleMessageChange(
        "Maximum values should be greater or equal to Minimum values"
      );
      setActiveToggle(true);
    } else if (conditionMinbyAboveMaxCheck.includes(false)) {
      //3 Prev MaxMIn Condititon Check
      toggleMessageChange(
        "Minimum value should be greater or equal to previous Maximum value"
      );
      setActiveToggle(true);
    } else if (radioDiscountedPrice || !radioDiscountedPrice) {
      //4,5,6 Radio Button, Discount value, LastField Condititon Check
      if (radioDiscountedPrice) {
        if (conditionDiscountAmountCheck.includes(false)) {
          toggleMessageChange(
            "Discount value should be less than or equal to previous Discount value"
          );
          setActiveToggle(true);
        } else if (conditionLastMaxCheck) {
          toggleMessageChange(
            "Leave Maximum quantity field blank for the last quantity range."
          );
          setActiveToggle(true);
        } else {
          setSaveTier("Update Tier");
        }
      } else {
        if (conditionDiscountAmountCheck.includes(false)) {
          toggleMessageChange(
            "Discount value should be greater or equal to previous Discount value"
          );
          setActiveToggle(true);
        } else if (conditionLastMaxCheck) {
          toggleMessageChange(
            "Leave Maximum quantity field blank for the last quantity range."
          );
          setActiveToggle(true);
        } else {
          setSaveTier("Update Tier");
        }
      }
    }
    var radioButtonValue = ""; //Get Discount type value
    if (radioPercentage) {
      radioButtonValue = "percentage";
    } else if (radioAmount) {
      radioButtonValue = "fixed";
    } else if (radioDiscountedPrice) {
      radioButtonValue = "fixed_price";
    }

    //All coditions Check & Submit
    if ( !reqFields.includes(false) && !conditionMaxbyMinCheck.includes(false) && !conditionMinbyAboveMaxCheck.includes(false) && !conditionDiscountAmountCheck.includes(false) && !conditionLastMaxCheck ) {
      setSaveTier("Update Tier");
      TierObject.discount_type = radioButtonValue;
      TierObject.tier_value = tier_value;
      var data = {
        TierObject: TierObject
      };
      setSaveTier("Updating..."); //set submit button loading
      setProgressLoading(true);
      setHoldPage(true);

      axios.post(config.APIURL + "/tier/EditCustomerTiers", data) //submit data
        .then(async(res) => {
          setActiveModal(!activeModal);
          setSuccessToggleMesssage("Tier Updated Sucessfully"); setSuccessActive(true); //Toast
        //Call List again on the existing page  
        var _url_ = config.APIURL + '/tier/getTierListByCustomer';
        var datatosend = { id: customerId, offset: paginationObj.globalOffset, limit: paginationObj.globalLimit };
        var refresh_ = await axios.post(_url_, datatosend);
        var Tiers = refresh_.data.tierList[0].TierObject;
        setObj([]);
        setTiers(Tiers);
        //Call List again on the existing page
        setProgressLoading(false);
        setHoldPage(false);
        })
        .catch((err) => {
          console.log(err);
        })

    } else {
      setSaveTier("Update Tier");
    }
  }

  var checkboxes = document.getElementsByClassName('checkboxes');
  var checkAll = document.getElementsByClassName('check-all');
  var delBulk = [];
  const allCheckbox = () => {
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkAll[0].checked) {
        checkboxes[i].checked = true;
      } else {
        checkboxes[i].checked = false;
      }
    }
  }
  const deleteBukTier = () => {
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        delBulk.push(checkboxes[i].name);
      }
    }
    if(delBulk.length == 0){
    }else{
    setProgressLoading(true);
    setHoldPage(true);
    // setHoldPage(true);
    var url = config.APIURL + '/tier/deleteBulkCustomertier';
    var data = { customer_id: customerId, entity_id: delBulk }
    axios.post(url, data)
      .then(async (res) => {
        setProgressLoading(false);
        setSuccessToggleMesssage("Tier Deleted Successfully");
        setSuccessActive(true);
        checkAll[0].checked = false;
        allCheckbox();
        
        if(paginationObj.globalOffset == 0){
          getCustomerById(customerId);
        }
        else{
          handlePrevList();
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  const redirect = () => {
    window.location.href = "/create-special-customer-tiers"
    }

  const handleSearch = useCallback( //handle search
    async (newValue) => {
      setproductSearchTextField(newValue);
      if(newValue != null && newValue != ""){
        setProgressLoading(true);
        setLoading(true);
        let url = config.APIURL + '/tier/getTierListByCustomer';
        let data = { id: customerId, offset: 0, limit: paginationObj.globalLimit ,  query: newValue };
        axios.post(url, data)
          .then(res => {
            console.log(res.data)
            var Tiers = res.data.tierList;
            console.log(Tiers);
            setTiers(Tiers);
            setProgressLoading(false);
            setHoldPage(false);
          })
          .catch((error) => {
            // console.log(error);
          })
        }
      else{
          getCustomerById(customerId);
        }
      }
      );
  const handleSearchClearButton = useCallback( () => {
        setproductSearchTextField("")
        getCustomerById(customerId);
        setLoading(false); 
      }, [] );
      
  const [entityIdToDelete, setEntityIdToDelete] = useState(0);
  const [active_, setActive_] = useState(false);
  const handleChange_ = useCallback((id) => { 
    setActive_(!active_);
    setEntityIdToDelete(id);
  }, [active_]);
  const activator_ = <Button onClick={handleChange_}>Open</Button>;

  const deleteTier = () => {
    setProgressLoading(true);
    setHoldPage(true);
    var url = config.APIURL + '/tier/deletetier';
    console.log(url)
    var data = { customerID: customerId, id: entityIdToDelete }
    console.log(data)
    axios.post(url, data)
      .then(async (res) => {
        setActive_(false);
        setSuccessToggleMesssage("Tier Deleted Successfully");
        setSuccessActive(true);
        //Call List again on the existing page  
        var _url_ = config.APIURL + '/tier/getTierListByCustomer';
        var datatosend = { id: customerId, offset: paginationObj.globalOffset, limit: paginationObj.globalLimit };
        var refresh_ = await axios.post(_url_, datatosend);
        var Tiers = refresh_.data.tierList[0].TierObject;
        setTiers(Tiers);
        //Call List again on the existing page
        setProgressLoading(false);
        setHoldPage(false);
        // getCustomerById(customerId);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
<div className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}>
      {/* {holdPage ?
      <div className="loading-spinner">
      <Spinner accessibilityLabel="Spinner example" size="large" color="teal" /> 
      </div> : null
      } */}
      
      <Frame>
        <Page 
        breadcrumbs={[{content: 'Customer List', url: '/customerlist'}]}
        title={'Tiered Discount List (' + customerName + ' - ' + customerEmail + ')'}>
          <PageActions
            primaryAction={{
              content: 'Create Tiers',
                 onClick: redirect //create button
            }}
            secondaryActions={[
              {
                content: 'Delete Tiers',
                destructive: true,
                onClick: deleteBukTier //delete button
              },
            ]}
          />

        <Modal
          activator={activator_}
          open={active_}
          onClose={handleChange_}
          title="Confirm Delete"
          primaryAction={{
            content: 'Delete',
            onAction: deleteTier,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleChange_,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                Are you sure you want to delete?
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>          


          {loadingMarkup}
          <Modal
            activator={activatorModal}
            open={activeModal}
            onClose={handleModalChange}
            title="Edit Discount"
            primaryAction={{ content: submitButtonText, onAction: ThemeSubmit, }}
            secondaryActions={[{ content: 'Cancel', onAction: handleModalChange, },
            ]}
          >
            <div className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}>
            {holdPage ?
      <div className="loading-modal-spinner">
      <Spinner accessibilityLabel="Spinner example" size="large" color="teal" /> 
      </div> : null
      }
              <Form>
                <Card>
                  <Card.Section>
                    {/* <Modal.Section> */}
                    <div className="custom-margin-bottom"><p>Identifier Type: <TextStyle variation="subdued">{TierObject.entity_type}</TextStyle></p></div>
                    <div className="custom-margin-bottom"><p>Identifier Title: <TextStyle variation="subdued">{TierObject.entity_title}</TextStyle></p></div>
                    <div className="custom-margin-bottom">
                      <p>
                        <Stack distribution="fill">
                          <p>Discount Type: <TextStyle variation="subdued"></TextStyle></p>
                          <RadioButton label="Percentage Off" checked={radioPercentage} id="r_percentage" name="percentage" onChange={handleRadio} />
                          <RadioButton label="Amount Off" checked={radioAmount} id="r_amount" name="amount" onChange={handleRadio} />
                          <RadioButton label="Discounted Price" checked={radioDiscountedPrice} id="r_discount" name="discount" onChange={handleRadio} />
                        </Stack>
                      </p>
                    </div>
                    <Stack distribution="fillEvenly">
                      <div className="custom-margin-bottom"><p>Minimum Quantity</p></div>
                      <div className="custom-margin-bottom"><p>Maximum Quantity</p></div>
                      <div className="custom-margin-bottom"><p>Discount Value</p></div>
                    </Stack>
                    {divValue.length > 0 ? (
                      <div className="">
                        {divValue.length > 0
                          ? divValue.map((item, i) => (
                            <span id={item} key={i} className="">
                              <Stack distribution="fill">
                                <div className="" > <div className="Polaris-Connected"> <div className="Polaris-Connected__Item Polaris-Connected__Item--primary"> <div className="Polaris-TextField"> <input type="number" id={"txtRow" + i + "Col1"} className={"Polaris-TextField__Input txtRow" + i + "Col1"} aria-labelledby="PolarisTextField5Label" aria-invalid="false" aria-multiline="false" defaultValue={obj[i] != null ? obj[i].MinQty : ''}></input> <div className="Polaris-TextField__Backdrop"></div></div></div></div></div>
                                <div className="" > <div className="Polaris-Connected"> <div className="Polaris-Connected__Item Polaris-Connected__Item--primary"> <div className="Polaris-TextField"> <input type="number" id={"txtRow" + i + "Col2"} className={"Polaris-TextField__Input txtRow" + i + "Col2"} aria-labelledby="PolarisTextField5Label" aria-invalid="false" aria-multiline="false" defaultValue={obj[i] != null ? obj[i].MaxQty : ''}></input> <div className="Polaris-TextField__Backdrop"></div></div></div></div></div>
                                <div className="custom-margin-bottom" > <div className="Polaris-Connected"> <div className="Polaris-Connected__Item Polaris-Connected__Item--primary"> <div className="Polaris-TextField"> <input type="number" id={"txtRow" + i + "Col3"} className={"Polaris-TextField__Input txtRow" + i + "Col3"} aria-labelledby="PolarisTextField5Label" aria-invalid="false" aria-multiline="false" defaultValue={obj[i] != null ? obj[i].Value : ''}></input> <div className="Polaris-TextField__Backdrop"></div></div></div></div></div>
                              </Stack>

                            </span>
                          ))
                          : null}
                      </div>
                    ) : null}

                    <div id="plusMinusButtonDiv" className="custom-margin-bottom">
                      <span id="plusDiv">
                        <Button onClick={handleplus}>Add Tier</Button>
                      </span>
                      <span id="minusDiv">
                        <Button onClick={handleMinus}>Remove Tier</Button>
                      </span>
                    </div>

                    <p className="custom-margin-bottom"> <b>Note: </b>Leave Maximum Quantity field blank for the last Quantity range. </p>

                    {/* <Stack distribution="fill">
                      <div>Start Date</div>
                      <div>End Date</div>
                    </Stack>

                    <Stack distribution="fill">
                      <TextField label="" value={textFieldValue} onChange={handleTextField} />
                      <TextField label="" value={textFieldValue} onChange={handleTextField} />
                    </Stack> */}
                  </Card.Section>
                </Card>
              </Form>
              {/* </Modal.Section> */}
            </div>
          </Modal>
          <Card>

            <Card.Section>
              <TextField 
                value={productSearchTextField} 
                placeholder="Search Tiers"   
                autoComplete="off"    
                onChange={handleSearch}
              clearButton 
              onClearButtonClick={handleSearchClearButton}
           
               >
              </TextField>
            </Card.Section>

            <table>
              <thead className="table-thead">
                <tr>
                  <td><input type='checkbox' className="check-all" onClick={() => allCheckbox()} style={{ marginLeft: '26px', marginRight: '-39px' }}></input></td>
                  <td className="thead-th" style={{ width: '380px', paddingLeft: '52px' }}>Title</td>
                  <td className="thead-th" style={{ width: '120px', paddingLeft: '52px' }}>Type</td>
                  <td className="thead-th" style={{ width: '240px', paddingLeft: '52px' }}>Created Date</td>
                  <td className="thead-th" style={{ width: '60px', paddingLeft: '42px' }}>Status</td>
                  <td className="thead-th" style={{ width: '80px', paddingLeft: '25px' }}>Actions</td>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
            <ResourceList
              resourceName={{ singular: 'Tier', plural: 'Tiers' }}
              items={tiers}
              renderItem={(item) => {
                const { entity_id, entity_title, entity_type, created_date, tier_status, discount_type, tier_value } = item;
                return (


                  <ResourceItem
                    id={entity_id}
                    // url={'/customer/' + entity_id}
                    accessibilityLabel={`View details for ${entity_title}`}
                  >
                    <Stack alignment="center">
                      <table>
                        <tbody>
                          <tr>
                            <td><input name={entity_id} className="checkboxes" type='checkbox' style={{ marginRight: '10px' }}></input></td>
                            <td className="" style={{ width: '380px' }}>{entity_title}</td>
                            <td className="" style={{ width: '120px' }}>{entity_type}</td>
                            <td className="" style={{ width: '235px' }}><Moment local format="DD/MM/YYYY HH:mm">{created_date}</Moment></td>
                            {/* https://www.npmjs.com/package/react-moment //REACCT DATE TIME FORMATS */}
                            <td className="" style={{ width: '70px' }}>
                              <span title={tier_status ? 'Enabled' : 'Disabled'} onClick={() => { enableDisableTier(entity_id, entity_title, entity_type, discount_type, created_date, tier_status, tier_value) }}>
                                {/* <i className={tier_status == true ? 'fas fa-toggle-on fa-1.5x' : 'fas fa-toggle-off fa-1.5x'}></i> */}
                                <div className={tier_status ? "Switch Round Off" : "Switch Round On" }>
                                  <div className="Toggle"></div>
                                </div>
                                </span>
                            </td>
                            <td className="" style={{ width: '60px' }}>
                              <span onClick={() => handleModalChange(entity_id, entity_title, entity_type, discount_type, created_date, tier_status, tier_value)} title="Edit"><i className="fas fa-edit fa-1.5x"></i></span>
                              <span onClick={() => { handleChange_(entity_id) }} style={{ paddingLeft: '20px' }} title="Close"><i className="fas fa-close fa-1.8x"></i></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Stack>
                  </ResourceItem>

                );
              }}
              isFiltered={false} showHeader={false}
            // selectable
            />

              {
                productSearchTextField == "" ?
                <Card.Section>
                <Stack spacing="loose" alignment="center" distribution="center">
                  {paginationObj.pageCounter > 1 ?
                    <Button onClick={handlePrevList}>Previous</Button>
                    : <Button onClick={handlePrevList} disabled>Previous</Button>
                  }
                  <span>{paginationObj.pageCounter}</span>
                  {paginationObj.remainingPages > 0 ?
                    <Button onClick={handleNextList}>Next</Button>
                    : <Button onClick={handleNextList} disabled>Next</Button>
                  }
                </Stack>
              </Card.Section>
  
               : null 
              }
            
          </Card>

        </Page>
        {successToast}
        {toastValidationError}
      </Frame >
    </div>
  );

}

export default CustomerComponent;