import React, { useEffect, useState, useCallback } from "react";
import {
  ResourceList,
  Page,
  Avatar,
  ResourceItem,
  FooterHelp,
  Loading,
  Heading,
  Toast,
  Frame,
  Spinner,
  Stack,
  Button,
  Card,
  PageActions,
  Select,
  RadioButton,
  FormLayout,
  TextField,
  DisplayText,
} from "@shopify/polaris";
import axios from "axios";
import config from "../config.json";
import "./css/productSearchHTML.css";

const CreatePage = (props) => {
  const [nextUrl, setNextUrl] = useState("");
  const [previousUrl, setPreviousUrl] = useState("");

  const [progressLoading, setProgressLoading] = useState(false);
  const loadingMarkup = progressLoading && <Loading />;

  const [errorActive, setErrorActive] = useState({
    activate: false,
    errorMessage: "",
  });
  const [loading, setLoading] = useState(false);

  const toastMarkup = errorActive.activate ? (
    <Frame>
      {" "}
      <Toast
        content={errorActive.errorMessage}
        onDismiss={() => setErrorActive({ activate: false, errorMessage: "" })}
      />
    </Frame>
  ) : null;

  const [successToast, setSuccessToast] = useState(false); //Success Toast
  const togglesetSuccessToast = useCallback(() => setSuccessToast((successToast) => !successToast), []);
  const toastSuccess = successToast ? (
    <Toast content="Created Successfully" onDismiss={togglesetSuccessToast} />
  ) : null;
  
  const [saveTier, setSaveTier] = useState("Create Tier");

  const [productList, setProductList] = useState("");
  const [customerList, setCustomerList] = useState("");

  let [htmlSelection, setHtmlSelection] = useState([]);
  let [customerHtmlSelection, setCustomerHtmlSelection] = useState([]);

  //Variants Search by field
  const [variantSearchIdentifier, setVariantSearchIdentifier] = useState("Search by Product Title");
  const variantOptions = [
    <option key="vTitle" value="Search by Product Title" label="Search by Product Title" ></option>,
    <option key="pTitle" value="Search by Variant Title" label="Search by Variant Title" ></option>,
    <option key="vSku" value="Search by SKU" label="Search by SKU" ></option>
  ];
  const handleVariantSearchChange = useCallback((e) => {
    setVariantSearchIdentifier(e.target.value);
  }, []);
  
  useEffect( () => {
    //On Page load
  }, [props]);

  //Create customer code

  const [selected, setSelected] = useState("select");

  const options = [
    { label: "Select Product Identifier", value: "select" },
    { label: "Products", value: "products" },
    { label: "Product Variants", value: "variants" },
    { label: "Collections", value: "collections" },
  ];

  const handleSelectChange = useCallback((value) => {
    setSelected(value);
    setHtmlSelection("");
  }, []);

  const [radioPercentage, setradioPercentage] = useState(true);
  const [radioAmount, setradioAmount] = useState(false);
  const [radioDiscountedPrice, setradioDiscountedPrice] = useState(false);
  const [holdPage, setHoldPage] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState();
  const handleTextField = useCallback(
    (newValue) => setTextFieldValue(newValue),
    []
  );

  const [customerSearchTextField, setcustomerSearchTextField] = useState();
  const handlecustomerSearchTextField = useCallback((newValue) => {
    //customer search textbox function
    setcustomerSearchTextField(newValue);
  }, []);

  const [productSearchTextField, setproductSearchTextField] = useState();
  const [filteredStates, setFilteredStates] = useState(productList);

  const doneTyping = async (newValue) => {
    setproductSearchTextField(newValue);
      if(newValue != null && newValue != ""){
        if(customerHtmlSelection.length != 0){
          if (selected != "select") {
            var productType = selected; //finals products array
            if (productType == "products") {
              var productSearch = await axios.get(config.APIURL + "/tier/ProductSearchByGraphQL?productType=" + productType + "&query=" + newValue + "&customerId=" + customerHtmlSelection[0].id);
              var data = productSearch.data.finalProductsArray;
              var ddt = [];
              var dLength = data.length;
              for (var x = 0; x < data.length; x++) {
                var cc = data[x];
                var c = {};
                c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
                c.title = cc.title;
                c.handle = cc.handle;
                c.price =
                  cc.priceRangeV2.minVariantPrice.amount +
                  " " +
                  cc.priceRangeV2.minVariantPrice.currencyCode;
                ddt.push(c);
              }
              setProductList(ddt);
            } 
            else if (productType == "variants") {
              var variantSearchBy = '';
              if(variantSearchIdentifier == 'Search by Variant Title'){variantSearchBy = 'vTitle'}
              else if(variantSearchIdentifier == 'Search by Product Title'){variantSearchBy = 'pTitle'}
              else{variantSearchBy = 'vSku'}
              var productSearch = await axios.get(config.APIURL + "/tier/ProductSearchByGraphQL?productType=" + productType + "&query=" + newValue + "&variantSearchby=" + variantSearchBy + "&customerId=" + customerHtmlSelection[0].id);
              if(variantSearchBy == 'pTitle'){
                var data = productSearch.data.finalProductsArray;
                var ddt = [];
                var dLength = data.length;
                for (var x = 0; x < data.length; x++) {
                  var cc = data[x];
                  var c = {};
                  c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
                  if(cc.sku != null && cc.sku != ""){
                    c.title = cc.product.title + ' | ' + cc.title + ' | ' + cc.sku;
                    c.handle = cc.sku;
                  }
                  else{
                  c.title = cc.product.title + ' | ' + cc.title;
                  c.handle = '';
                  }
                  c.price = cc.price;
                  ddt.push(c);
                }
              }
              else{
                var data = productSearch.data.finalProductsArray;
                var ddt = [];
                var dLength = data.length;
                for (var x = 0; x < data.length; x++) {
                  var cc = data[x];
                  var c = {};
                  c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
                  if(cc.sku != null && cc.sku != ""){
                    c.title = cc.product.title + ' | ' + cc.title + ' | ' + cc.sku;
                    c.handle = cc.sku;
                  }
                  else{
                  c.title = cc.product.title + ' | ' + cc.title;
                  c.handle = '';
                  }
                  c.price = cc.price;
                  ddt.push(c);
                }
              }
              setProductList(ddt);
            } else if (productType == "collections") {
              var productSearch = await axios.get(config.APIURL + "/tier/ProductSearchByGraphQL?productType=" + productType + "&query=" + newValue + "&customerId=" + customerHtmlSelection[0].id);
              var data = productSearch.data.finalProductsArray;
              var ddt = [];
              var dLength = data.length;
              for (var x = 0; x < data.length; x++) {
                var cc = data[x];
                var c = {};
                c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
                c.title = cc.title;
                c.handle = cc.handle;
                ddt.push(c);
              }
              setProductList(ddt);
            }
          } else {
            setproductSearchTextField("Select Product Identifier");
            setTimeout(async () => {
              setproductSearchTextField("");
            }, 1000);
          }
        }
        else{
          alert('Please Select Customer')
        }
      }
      else{
        handleproductSearchClick();
      }
  }
  const [typingTimer, setTypingTimer] = useState(); 
  //Product search textbox function with ""Change"" event
  const handleproductSearchChange = useCallback(async (newValue) => {
    setproductSearchTextField(newValue);
    clearTimeout(typingTimer);
    if (newValue) {
      const typingTimer_ = setTimeout(async () => {
        doneTyping(newValue)
      }, 2000);
      setTypingTimer(typingTimer_);
    }
  });

  //Product Search Clear Textfield ""Click"" event
  const handleProductSearchClearButtonClick = useCallback( () => setproductSearchTextField(""), [] );

  //Product search textbox function with ""Click"" event
  const handleproductSearchClick = async (newValue) => {
    if(customerHtmlSelection.length != 0){
      var productType = selected; //Get Products list here
      var productSearch = await axios.get(
        config.APIURL + "/tier/ProductSearchByGraphQL?productType=" + productType + "&customerId=" + customerHtmlSelection[0].id
      );
      //finals products array
      if(selected != "select"){
        if (productType == "products") {
          var data = productSearch.data.finalProductsArray;
          var ddt = [];
          for (var x = 0; x < data.length; x++) {
            var cc = data[x];
            var c = {};
            c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
            c.title = cc.title;
            c.handle = cc.handle;
            c.price =
              cc.priceRangeV2.minVariantPrice.amount +
              " " +
              cc.priceRangeV2.minVariantPrice.currencyCode;
            ddt.push(c);
          }
          setProductList(ddt);
        } else if (productType == "variants") {
          var data = productSearch.data.finalProductsArray;
          var ddt = [];
          var dLength = data.length;
          for (var x = 0; x < data.length; x++) {
            var cc = data[x];
            var c = {};
            c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
            if(cc.sku != null && cc.sku != ""){
              c.title = cc.product.title + ' | ' + cc.title + ' | ' + cc.sku;
              c.handle = cc.sku;
            }
            else{
            c.title = cc.product.title + ' | ' + cc.title;
            c.handle = '';
            }
            c.price = cc.price;
            ddt.push(c);
          }
          setProductList(ddt);
        } else if (productType == "collections") {
          var data = productSearch.data.finalProductsArray;
          var ddt = [];
          var dLength = data.length;
          for (var x = 0; x < data.length; x++) {
            var cc = data[x];
            var c = {};
            c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
            c.title = cc.title;
            c.handle = cc.handle;
            c.price = cc.price;
            ddt.push(c);
          }
          setProductList(ddt);
        }
      }
      else{
        setproductSearchTextField("Select Product Identifier");
        setTimeout(async () => {
          setproductSearchTextField("");
        }, 1000);
      }
    }
    else{
      alert('Please Select Customer')
    }
  };

  //Customer search textbox function with ""Change"" event
  const handleCustomerSearchChange = useCallback(async (newValue) => {
    setcustomerSearchTextField(newValue);

    axios
      .get(config.APIURL + "/tier/getallcustomer?query=" + newValue)
      .then((res) => {
        var data = res.data.customer.data.customers.edges;
        var ddt = [];
        var dLength = data.length;
        for (var x = 0; x < data.length; x++) {
          var cc = data[x].node;
          var c = {};
          c.displayName = cc.displayName;
          c.email = cc.email;
          c.phone = cc.phone;
          c.tags = cc.tags;
          c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
          ddt.push(c);
        }
        setCustomerList(ddt);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //Customer Search Clear Textfield ""Click"" event
  const handleCustomerSearchClearButtonClick = useCallback( () => setcustomerSearchTextField(""), [] );

  //Customer search textbox function with ""Click"" event
  const handleCustomerSearchClick = async (newValue) => {
    axios
      .get(config.APIURL + "/tier/getallcustomer")
      .then((res) => {
        var data = res.data.customer.data.customers.edges;
        var ddt = [];
        var dLength = data.length;
        for (var x = 0; x < data.length; x++) {
          var cc = data[x].node;
          var c = {};
          c.displayName = cc.displayName;
          c.email = cc.email;
          c.phone = cc.phone;
          c.tags = cc.tags;
          c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
          ddt.push(c);
        }
        setCustomerList(ddt);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleRadio = useCallback((_checked, newValue) => {
    switch (newValue) {
      case "r_percentage":
        setradioPercentage(true);
        setradioAmount(false);
        setradioDiscountedPrice(false);
        break;
      case "r_amount":
        setradioPercentage(false);
        setradioAmount(true);
        setradioDiscountedPrice(false);
        break;
      case "r_discount":
        setradioPercentage(false);
        setradioAmount(false);
        setradioDiscountedPrice(true);
        break;
    }
  }, []);

  //products selected field
  const handleResourceItem = (id, title, price, handle) => {
    if (htmlSelection.length == 0) {
      setHtmlSelection((oldArray) => [...oldArray, { id, title, price, handle }]);
    } else {
      var flag = false;
      htmlSelection.filter((item) => {
        if (item.id == id) {
          flag = true;
        }
      });
      if (!flag) {
        setHtmlSelection((oldArray) => [...oldArray, { id, title, price, handle }]);
      }
    }
  };

  const handleRemoveItem = (id) => {
    setHtmlSelection(htmlSelection.filter((item) => item.id !== id));
  };
  
  //Customer selected field
  const handleCustomerResourceItem = (id, displayName, email) => {
    if (customerHtmlSelection.length == 0) {
      setCustomerHtmlSelection((oldArray) => [
        // ...oldArray,
        { id, displayName, email },
      ]);
    } else {
      var flag = false;
      customerHtmlSelection.filter((item) => {
        if (item.id == id) {
          flag = true;
        }
      });
      if (!flag) {
        setCustomerHtmlSelection((oldArray) => [
          // ...oldArray,
          { id, displayName, email },
        ]);
      }
    }
  };

  const handleCustomerRemoveItem = (id) => {
    setCustomerHtmlSelection(
      customerHtmlSelection.filter((item) => item.id !== id)
    );
  };

  const handleProductCustomerListHide = () => { //All List hide function
    setProductList("");
    setCustomerList("");
  };

  let [divValue, setDivValue] = useState([1]);
  const handleplus = () => {
    if (divValue.length >= 1) {
      setDivValue((oldArray) => [...oldArray, divValue]);
    }
  };
  const handleMinus = () => {
    if (divValue.length > 1) {
      setDivValue(divValue.pop());
    }
  };

  const [activeToggle, setActiveToggle] = useState(false); //Error Toast Polaris
  const [toggleMesssage, setToggleMesssage] = useState("");
  const toggleMessageChange = useCallback( (value) => setToggleMesssage(value), [] );
  const toggleActive = useCallback( () => setActiveToggle((activeToggle) => !activeToggle), [] );
  const toastValidationError = activeToggle ? ( <Toast content={toggleMesssage} error onDismiss={toggleActive} duration={4500} /> ) : null;

  const handleSubmit = () => {
    setSaveTier("Saving..."); //set submit button loading
    var reqFields = [];
    var conditionMaxbyMinCheck = [],
      conditionMinbyAboveMaxCheck = [],
      conditionPercentageAmountCheck = [],
      conditionDiscountAmountCheck = [];
    var conditionLastMaxCheck = false;
    var tier_value = ""; // tier value string

    for (var i = 0; i < divValue.length; i++) {
      var allMinValues = Number(
        document.getElementById("txtRow" + i + "Col1").value
      );
      var allMaxValues = Number(
        document.getElementById("txtRow" + i + "Col2").value
      );
      var allDiscountValues = Number(
        document.getElementById("txtRow" + i + "Col3").value
      );
      var allMaxValuesOfAboveRow = 0,
        allDiscountValuesOfAboveRow = 0;
      if (i == divValue.length - 1) {
        tier_value =
          tier_value + allMinValues + "-" + "max" + "=" + allDiscountValues;
      } else {
        tier_value =
          tier_value +
          allMinValues +
          "-" +
          allMaxValues +
          "=" +
          allDiscountValues +
          ",";
      }

      //FieldsInput
      if (i > 0) {
        var minus = i - 1;
        allMaxValuesOfAboveRow = Number(
          document.getElementById("txtRow" + minus + "Col2").value
        );
        allDiscountValuesOfAboveRow = Number(
          document.getElementById("txtRow" + minus + "Col3").value
        );
      }
      //FieldsRequiredValidationCheck
      if (
        allMinValues == null ||
        allMinValues == "" ||
        allDiscountValues == null ||
        allDiscountValues == "" ||
        (allMaxValues == null && i != divValue.length - 1) ||
        (allMaxValues == "" && i != divValue.length - 1)
      ) {
        reqFields.push(false);
      } else {
        reqFields.push(true);
      }
      //conditionMaxbyMinCheck
      if (i == divValue.length - 1 || allMaxValues >= allMinValues) {
        if (i == divValue.length - 1) {
          if (allMaxValues == null || allMaxValues == "") {
            conditionMaxbyMinCheck.push(true);
          }
          //  else {
          //   conditionMaxbyMinCheck.push(false);
          // }
        } else {
          conditionMaxbyMinCheck.push(true);
        }
      } else {
        conditionMaxbyMinCheck.push(false);
      }
      //conditionDiscountAmountCheck
      if (i > 0) {
        if (radioDiscountedPrice) {
          if (allDiscountValues <= allDiscountValuesOfAboveRow) {
            conditionDiscountAmountCheck.push(true);
          } else {
            conditionDiscountAmountCheck.push(false);
          }
        } else {
          if (allDiscountValues >= allDiscountValuesOfAboveRow) {
            conditionDiscountAmountCheck.push(true);
          } else {
            conditionDiscountAmountCheck.push(false);
          }
        }
      }
      //ValidationMinToPreMaxValueCheck
      if (i > 0) {
        if (allMinValues >= allMaxValuesOfAboveRow) {
          conditionMinbyAboveMaxCheck.push(true);
        } else {
          conditionMinbyAboveMaxCheck.push(false);
        }
      }
      //validateMaxTierEmptyCheck
      if (i == divValue.length - 1) {
        if (allMaxValues != null && allMaxValues != "") {
          conditionLastMaxCheck = true;
        }
      }
    }
    //AllAlerts
    if(selected == "select"){
      toggleMessageChange("Please select product identifier");
      setActiveToggle(true);
    }
    else if (htmlSelection.length < 1) {
      toggleMessageChange("Please select products");
      setActiveToggle(true);
    }
   else if (customerHtmlSelection.length < 1) {
      toggleMessageChange("Please select customers");
      setActiveToggle(true);
    }
    else if (reqFields.includes(false)) {
      //1 Condititon field required Check
      toggleMessageChange("Fields Required");
      setActiveToggle(true);
    } else if (conditionMaxbyMinCheck.includes(false)) {
      //2 MaxMIn Condititon Check
      toggleMessageChange(
        "Maximum values should be greater or equal to Minimum values"
      );
      setActiveToggle(true);
    } else if (conditionMinbyAboveMaxCheck.includes(false)) {
      //3 Prev MaxMIn Condititon Check
      toggleMessageChange(
        "Minimum value should be greater or equal to previous Maximum value"
      );
      setActiveToggle(true);
    } else if (radioDiscountedPrice || !radioDiscountedPrice) {
      //4,5,6 Radio Button, Discount value, LastField Condititon Check
      if (radioDiscountedPrice) {
        if (conditionDiscountAmountCheck.includes(false)) {
          toggleMessageChange(
            "Discount value should be less than or equal to previous Discount value"
          );
          setActiveToggle(true);
        } else if (conditionLastMaxCheck) {
          toggleMessageChange(
            "Leave Maximum quantity field blank for the last quantity range."
          );
          setActiveToggle(true);
        } else {
          setSaveTier("Create Tier");
        }
      } else {
        if (conditionDiscountAmountCheck.includes(false)) {
          toggleMessageChange(
            "Discount value should be greater or equal to previous Discount value"
          );
          setActiveToggle(true);
        } else if (conditionLastMaxCheck) {
          toggleMessageChange(
            "Leave Maximum quantity field blank for the last quantity range."
          );
          setActiveToggle(true);
        } else {
          setSaveTier("Create Tier");
        }
      }
    }
    
    var radioButtonValue = ""; //Get Discount type value
    if (radioPercentage) {
      radioButtonValue = "percentage";
    } else if (radioAmount) {
      radioButtonValue = "fixed";
    } else if (radioDiscountedPrice) {
      radioButtonValue = "fixed_price";
    }

    //All coditions Check & Submit
    if (
      !reqFields.includes(false) &&
      !conditionMaxbyMinCheck.includes(false) &&
      !conditionMinbyAboveMaxCheck.includes(false) &&
      !conditionDiscountAmountCheck.includes(false) &&
      !conditionLastMaxCheck &&
      (htmlSelection.length > 0) && (customerHtmlSelection.length > 0) &&
      selected != "select"
    ) {
      setHoldPage(true)
      setProgressLoading(true);
      var data = {
        productList: htmlSelection,
        customerList: customerHtmlSelection,
        entity_type: selected,
        discount_type: radioButtonValue,
        tier_value: tier_value,
      };
      console.log(data);
      axios.post(config.APIURL + "/tier/CreateTiers", data) //submit data
      .then((res) => {
        console.log(res);
        setDivValue('');
        setHoldPage(false)
        setProgressLoading(false);
        setSuccessToast(true);
        setHtmlSelection('');
        setCustomerHtmlSelection('');
        setDivValue(1);
        window.location.reload();
      })
      .catch((err) =>{
        console.log(err);
      })

    } else {
      setSaveTier("Create Tier");
    }
  };

  return (
    <div
    className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}
  >
    {/* {holdPage ?
    <div className="loading-spinner">
    <Spinner accessibilityLabel="Spinner example" size="large" color="teal" /> 
    </div> : null
    } */}
    <Frame>
      <div onClick={handleProductCustomerListHide}>
        <Page
         breadcrumbs={[{ content: "Customer List", url: "/customerlist" }]} 
        title="Create tiered discount for Special Customers">
          {loadingMarkup}
          <PageActions />
          <Card>
            <Card.Section>

              {/* Customer Section */}
              <FormLayout.Group>
                <DisplayText size="small">Select Customer:</DisplayText>
              </FormLayout.Group>

              <FormLayout.Group>
                <TextField
                  label=""
                  value={customerSearchTextField}
                  onChange={handleCustomerSearchChange}
                  onFocus={handleCustomerSearchClick}
                  autoComplete="off"
                  placeholder="Click to show customers list ↴"
                  clearButton
                  onClearButtonClick={handleCustomerSearchClearButtonClick}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                {customerHtmlSelection.length > 0 ? (
                  <div className="productSeletionBarContainer">
                    {customerHtmlSelection.length > 0
                      ? customerHtmlSelection.map((item, i) => (
                          <span
                            id={item.id}
                            key={i}
                            className="productSeletionBarSpanMain"
                          >
                            <span className="Polaris-Tag Polaris-Tag--removable productSeletionBarSpan1">
                              <span
                                title={item.title}
                                className="Polaris-Tag__TagText"
                              >
                                {item.displayName}[{item.email}]
                              </span>
                              <button
                                type="button"
                                onClick={() =>
                                  handleCustomerRemoveItem(item.id)
                                }
                                aria-label={item.id}
                                className="Polaris-Tag__Button"
                              >
                                <span className="Polaris-Icon">
                                  <svg
                                    viewBox="0 0 20 20"
                                    className="Polaris-Icon__Svg"
                                    focusable="false"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </span>
                          </span>
                        ))
                      : null}
                  </div>
                ) : null}
              </FormLayout.Group>

              <FormLayout.Group>
                {customerList.length > 0 ? (
                  <div id="ResourceListContainer">
                    <ResourceList
                      items={customerList}
                      renderItem={(item) => {
                        const { id, displayName, email } = item;
                        return (
                          <ResourceItem
                            id={id}
                            // url={'/customer'}
                            onClick={() =>
                              handleCustomerResourceItem(id, displayName, email)
                            }
                            accessibilityLabel={`View details for ${displayName}`}
                          >
                            <div>
                              {displayName}[{email}]
                            </div>
                          </ResourceItem>
                        );
                      }}
                      // selectable
                    />
                  </div>
                ) : null}
              </FormLayout.Group>
              {/* Customer Section End */}


              {/* Product Section */}
              <FormLayout.Group>
                <DisplayText size="small">Select Products:</DisplayText>
              </FormLayout.Group>

              <FormLayout.Group>
                <Select
                  options={options}
                  onChange={handleSelectChange}
                  value={selected}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <TextField
                  label=""
                  value={productSearchTextField}
                  onChange={handleproductSearchChange}
                  onFocus={handleproductSearchClick}
                  autoComplete="off"
                  placeholder="Click to show product list ↴"
                  clearButton

                  connectedRight={
                    selected == 'variants' ? 
                    <div className="">
                    <div className="Polaris-Select" value={variantSearchIdentifier}>
                      <select
                        onChange={handleVariantSearchChange}
                        id="PolarisSelect1"
                        className="Polaris-Select__Input"
                        aria-invalid="false"
                        defaultValue={variantSearchIdentifier}
                      >
                        {variantOptions}
                      </select>
                      <div
                        className="Polaris-Select__Content"
                        aria-hidden="true"
                      >
                        <span className="Polaris-Select__SelectedOption">
                          {variantSearchIdentifier}
                        </span>
                        <span className="Polaris-Select__Icon">
                          <span className="Polaris-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              className="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path
                                d="M13 8l-3-3-3 3h6zm-.1 4L10 14.9 7.1 12h5.8z"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      </div>
                      <div className="Polaris-Select__Backdrop"></div>
                    </div>
                  </div>
                    : null
                  }
                  onClearButtonClick={handleProductSearchClearButtonClick}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                {htmlSelection.length > 0 ? (
                  <div className="productSeletionBarContainer">
                    {htmlSelection.length > 0
                      ? htmlSelection.map((item, i) => (
                          <span
                            id={item.id}
                            key={i}
                            className="productSeletionBarSpanMain"
                          >
                            <span className="Polaris-Tag Polaris-Tag--removable productSeletionBarSpan1">
                              <span
                                title={item.title}
                                className="Polaris-Tag__TagText"
                              >
                                {item.price != null ? (
                                  <span>
                                    {item.title} | {item.price}
                                  </span>
                                ) : (
                                  <span>{item.title}</span>
                                )}
                              </span>
                              <button
                                type="button"
                                onClick={() => handleRemoveItem(item.id)}
                                aria-label={item.id}
                                className="Polaris-Tag__Button"
                              >
                                <span className="Polaris-Icon">
                                  <svg
                                    viewBox="0 0 20 20"
                                    className="Polaris-Icon__Svg"
                                    focusable="false"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </span>
                          </span>
                        ))
                      : null}
                  </div>
                ) : null}
              </FormLayout.Group>

              <FormLayout.Group>
                {productList.length > 0 ? (
                  <div
                    id="ResourceListContainer" /*onBlur={handleProductListHide}*/
                  >
                    <ResourceList
                      items={productList}
                      renderItem={(item) => {
                        const { id, title, price, handle } = item;
                        return (
                          <ResourceItem
                            id={id}
                            // url={'/customer'}
                            onClick={() => handleResourceItem(id, title, price, handle)}
                            accessibilityLabel={`View details for ${title}`}
                          >
                            {price != null ? (
                              <div>
                                {title} | {price}
                              </div>
                            ) : (
                              <div>{title}</div>
                            )}
                          </ResourceItem>
                        );
                      }}
                      // selectable
                    />
                  </div>
                ) : null}
              </FormLayout.Group>
              {/* Product Section End */}

              <FormLayout.Group>
                <DisplayText size="small">Select Discount Type:</DisplayText>
              </FormLayout.Group>

              <FormLayout.Group>
                <RadioButton
                  label="Percentage Off"
                  checked={radioPercentage}
                  id="r_percentage"
                  name="percentage"
                  onChange={handleRadio}
                />
                <RadioButton
                  label="Amount Off"
                  checked={radioAmount}
                  id="r_amount"
                  name="amount"
                  onChange={handleRadio}
                />
                <RadioButton
                  label="Discounted Price"
                  checked={radioDiscountedPrice}
                  id="r_discount"
                  name="discount"
                  onChange={handleRadio}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                {/* <DisplayText label="Minimum Quantity" />
                <DisplayText label="Maximum Quantity" />
                <DisplayText label="Discount Value" /> */}
                <DisplayText size="small">Minimum Quantity</DisplayText>
                <DisplayText size="small">Maximum Quantity</DisplayText>
                <DisplayText size="small">Discount Value</DisplayText>
              </FormLayout.Group>

              <FormLayout>
                {divValue.length > 0 ? (
                  <div className="">
                    {divValue.length > 0
                      ? divValue.map((item, i) => (
                          <span id={item} key={i} className="">
                            <FormLayout.Group>
                              {/* <TextField className={i} value={textFieldValue} onChange={handleTextField} />
                              <TextField className={i} value={textFieldValue} onChange={handleTextField} />
                              <TextField className={i} value={textFieldValue} onChange={handleTextField} /> */}
                              <div className="">
                                <div className="Polaris-Connected">
                                  <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                    <div className="Polaris-TextField">
                                      <input
                                        type="number"
                                        id={"txtRow" + i + "Col1"}
                                        className={
                                          "Polaris-TextField__Input txtRow" +
                                          i +
                                          "Col1"
                                        }
                                        aria-labelledby="PolarisTextField5Label"
                                        aria-invalid="false"
                                        aria-multiline="false"
                                        value={textFieldValue}
                                      ></input>
                                      <div className="Polaris-TextField__Backdrop"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="">
                                <div className="Polaris-Connected">
                                  <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                    <div className="Polaris-TextField">
                                      <input
                                        type="number"
                                        id={"txtRow" + i + "Col2"}
                                        className={
                                          "Polaris-TextField__Input txtRow" +
                                          i +
                                          "Col2"
                                        }
                                        aria-labelledby="PolarisTextField5Label"
                                        aria-invalid="false"
                                        aria-multiline="false"
                                        value={textFieldValue}
                                      ></input>
                                      <div className="Polaris-TextField__Backdrop"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="">
                                <div className="Polaris-Connected">
                                  <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                    <div className="Polaris-TextField">
                                      <input
                                        type="number"
                                        id={"txtRow" + i + "Col3"}
                                        className={
                                          "Polaris-TextField__Input txtRow" +
                                          i +
                                          "Col3"
                                        }
                                        aria-labelledby="PolarisTextField5Label"
                                        aria-invalid="false"
                                        aria-multiline="false"
                                        value={textFieldValue}
                                      ></input>
                                      <div className="Polaris-TextField__Backdrop"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </FormLayout.Group>
                          </span>
                        ))
                      : null}
                  </div>
                ) : null}
              </FormLayout>

              <FormLayout.Group>
                <div id="plusMinusButtonDiv">
                  <span id="plusDiv">
                    <Button onClick={handleplus}>Add Tier</Button>
                  </span>
                  <span id="minusDiv">
                    <Button onClick={handleMinus}>Remove Tier</Button>
                  </span>
                </div>
              </FormLayout.Group>

              <FormLayout.Group>
                <DisplayText size="small">
                  <b>Note: </b>Leave Maximum Quantity field blank for the last
                  Quantity range.
                </DisplayText>
              </FormLayout.Group>
{/* 
              <FormLayout.Group>
                <TextField
                  label="Start Date"
                  value={textFieldValue}
                  onChange={handleTextField}
                />
                <TextField
                  label="End Date"
                  value={textFieldValue}
                  onChange={handleTextField}
                />
              </FormLayout.Group> */}

              <FormLayout.Group>
                <Button primary onClick={handleSubmit}>
                  {saveTier}
                </Button>
              </FormLayout.Group>
            </Card.Section>
          </Card>
          {toastMarkup}
          {toastSuccess}
          {toastValidationError}
        </Page>
      </div>
    </Frame>
    </div>
  );
  // }
};

export default CreatePage;
