import React, { useEffect, useState, useCallback } from "react";
import {
  ResourceList,
  Modal,
  Page,
  Badge,
  Spinner,
  Avatar,
  ResourceItem,
  TextContainer,
  FooterHelp,
  Loading,
  Heading,
  Toast,
  Frame,
  Stack,
  Button,
  Card,
  TextStyle,
  InlineError,
  DisplayText,
  FormLayout,
  PageActions,
  Layout,
  Link,
  TextField,
  Checkbox,
} from "@shopify/polaris";
import axios from "axios";
import config from "../config.json";
import { PageItem } from "react-bootstrap";
import Moment from "react-moment";
import "./css/productSearchHTML.css";

const OrderDetail = (props) => {

  const [orderObject, setorderObject] = useState("");
  const [metafieldObject, setmetafieldObject] = useState("");
  const [currencySymbol, setcurrencySymbol] = useState("$");
  const [currency, setcurrency] = useState("USD");

  const [customerList, setCustomerList] = useState(orderObject.line_items != null ? orderObject.line_items : []);
  const [nextUrl, setNextUrl] = useState("");
  const [previousUrl, setPreviousUrl] = useState("");
  const [cusId, setCusId] = useState();
  const [progressLoading, setProgressLoading] = useState(false);
  const [loadingUpdateProduct, setloadingUpdateProduct] = useState(false);
  const [loadingEditNotes, setloadingEditNotes] = useState(false);
  const [loadingContactInfo, setloadingContactInfo] = useState(false);
  const [loadingShippingAddress, setloadingShippingAddress] = useState(false);
  const [globalId, setglobalId] = useState(props.match.params.id);
  const loadingMarkup = progressLoading && <Loading />;
  const [customerSearchTextField, setcustomerSearchTextField] = useState();
  const [qtyValue, setqtyValue] = useState("1");
  const [loadingsubmit, setloadingsubmit] = useState(false);
  const [orderoriginaltotal, setorderoriginaltotal] = useState();
  const [original_total_price_before_discount, setoriginal_total_price_before_discount] = useState(0);
  const [pricediff, setpricediff] = useState(0);
  let globalLimit = 50;

  //Toast success
  /* Func calls
  setSuccessActive(true);
  setSuccessToggleMesssage("Sample toast message");
  */
  const [successActive, setSuccessActive] = useState(false); //success Toast
  const [successToggleMesssage, setSuccessToggleMesssage] = useState("");
  const toggleSuccessActive = useCallback(() => setSuccessActive((successActive) => !successActive), []);
  const successToast = successActive ? (
    <Toast content={successToggleMesssage} onDismiss={toggleSuccessActive} />
  ) : null;

  //Toast error
  /* Func calls
  seterrorActive(true);
  seterrorToggleMesssage("Sample error message");
  */
  const [errorActive, seterrorActive] = useState(false); //error Toast
  const [errorToggleMesssage, seterrorToggleMesssage] = useState("");
  const toggleerrorActive = useCallback(() => seterrorActive((errorActive) => !errorActive), []);
  const errorToast = errorActive ? (
    <Toast error content={errorToggleMesssage} onDismiss={toggleerrorActive} />
  ) : null;

  const [paginationObj, setPaginationObj] = useState({});
  const [isLineItemEdit, setisLineItemEdit] = useState(false);
  const [orderName, setorderName] = useState('');
  const[customername,setcustomerName]=useState('');

  useEffect(async () => {
    await getAllOrder();
  }, [props]);
  function formatMoney(x) {
    try{
      x=x.toString();
      if(x.toString().includes('-')){
        x=x.replace(/\-/g, "");
        x="-$"+x;
      }
      else{
        x="$"+x;
      }
    var tmp = x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return tmp;
    }catch(e){return "NaN"}
  }
  const getAllOrder = async () => {
    setProgressLoading(true);    
    var temoorderobj= {};
    //alert(globalId)
    var url_ = config.APIURL + "/order/getorderdetail?id=" + globalId;
    // var data = { offset: 0, limit: globalLimit };
    var res = await axios.get(url_);
    // axios
    //   .get(url_)
    //   .then((res) => {
        //console.log("order object");
        //console.log(res.data.orderdetails);
        // var temoorderobj = {};
        temoorderobj = res.data.orderdetails;
        setorderObject(temoorderobj);
        setorderoriginaltotal(temoorderobj.subtotal_price);
        setCustomerList(temoorderobj.line_items);
        //console.log(temoorderobj);

        setmetafieldObject(res.data.allmetafields);

        var data = res.data.orderdetails.line_items;
        setorderName(res.data.orderdetails.name);
        setcustomerName(temoorderobj.customer!=null?temoorderobj.customer.default_address.name:"")
        setFirstName(temoorderobj.shipping_address.first_name);  
        setLastName(temoorderobj.shipping_address.last_name);  
        setCompany(temoorderobj.shipping_address.company);  
        setAddress(temoorderobj.shipping_address.address1);
        setApparment(temoorderobj.shipping_address.address2);    
        setCountry(temoorderobj.shipping_address.country);  
        setState(temoorderobj.shipping_address.province);  
        setCity(temoorderobj.shipping_address.city);  
        setZip(temoorderobj.shipping_address.zip);  
        setPhone(temoorderobj.shipping_address.phone);   
        setNotes(temoorderobj.note)
setContactInfo(temoorderobj.email)   
        // //console.log("properties");
        // //console.log(res.data.orderdetails.line_items.properties);
        setCusId(res.data.orderdetails.customer!=null ?res.data.orderdetails.customer.id:"");
        // //console.log(data);
        var ddt = [];
        // var dLength = data.length;
        // setPreviousUrl(data[0].cursor);
        // var last = dLength > 0 ? (dLength - 1) : 0;
        // setNextUrl(data[last].cursor)
        for (var x = 0; x < data.length; x++) {
          var cc = data[x];
          // //console.log("---------------------------------");
          // //console.log(cc.variant_id);
          var c = {};

          c.id = cc.id;
          c.name_ = cc.name;
          c.price = cc.price;
          c.quantity = cc.quantity;
          c.total = Number(cc.price) * cc.quantity;
          c.variant_id = cc.variant_id;
          c.variant_title = cc.variant_title
          c.properties = cc.properties
          c.sku = cc.sku
          if(cc.fulfillable_quantity > 0){
            ddt.push(c);
          }
        }
        // //console.log("ddt");
        // //console.log(ddt);
        

        setProgressLoading(false);
      // })
      // .catch((error) => {
      //   //console.log(error);
      // });
      return temoorderobj.line_items;
  };
  const redirect = (id) => {
    //alert("modal")
    handleChange_create();
  };

  const textFieldChange = (value, id, name_, price, quantity, variant_id) => {
    //console.log("helow");
    //console.log(value);
    //console.log(customerList);
    var tempList = [];

    if (value >= 1) {
      for (var i = 0; i < customerList.length; i++) {
        if (customerList[i].id == id) {
          //alert("true")
          tempList.push(customerList[i]);
          customerList[i].quantity = value;
          customerList[i].total = value * Number(price);
        } else {
          tempList.push(customerList[i]);
        }
      }

      setCustomerList(tempList);

    }

  };

  const [entityIdToDelete, setEntityIdToDelete] = useState(0);
  const [active_, setActive_] = useState(false);
  const handleChange_ = useCallback(
    (id) => {
      setActive_(!active_);
      setEntityIdToDelete(id);
    },
    [active_]
  );

  const [active_update, setActive_update] = useState(false);
  const handleChange_update= useCallback(
    () => {
      setActive_update(!active_update);
    },
    [active_update]
  );
  const[idToEdit,setIdToEdit]=useState();
  const updateLineItem = () => {
    setisLineItemEdit(true);
    setloadingUpdateProduct(true);
    //console.log(idToEdit)
    if (qtyValue >= 1) {
      var tempOrderObject = orderObject;
      var li = tempOrderObject.line_items;
      var templi = [];
      for (var i = 0; i < li.length; i++) {
        if (li[i].id == idToEdit) {
          //alert("true")
          templi.push(li[i]);
          templi[i].quantity = qtyValue;
          templi[i].total = qtyValue * Number(editModalVar.price);
        } else {
          templi.push(li[i]);
        }
      }

    setorderObject(tempOrderObject);
    setCustomerList(templi);
    updateOrderObject();

    }
  

    //console.log(tempOrderObject);
  
    setloadingUpdateProduct(false);
    setActive_update(false)

    // handleChange_update();     editModalVar.price * qtyValue
  };

  const [active_create, setActive_create] = useState(false);
  const handleChange_create = useCallback(
    () => {
      setActive_create(!active_create);
    },
    [active_create]
  );
  const deleteLineItem = () => {
    setisLineItemEdit(true);
    //console.log('id to delete = '+entityIdToDelete)
    var tempOrderObject = orderObject;
    var li = tempOrderObject.line_items;
    var templi = [];
    for (var i = 0; i < li.length; i++) {
      if (li[i].id != entityIdToDelete) {
        templi.push(li[i]);
      }
    }
    tempOrderObject.line_items = templi;

    //console.log(tempOrderObject);
    setorderObject(tempOrderObject);
    setCustomerList(templi);
    updateOrderObject();
    handleChange_();
  };

  const getmetafieldCall = async (variant_id) => {
    // //console.log('reached meta func call');
    var tempmetafieldObject = metafieldObject;
    var flagmeta = true;
    for(var i = 0; i < metafieldObject.length; i++){
      if(metafieldObject[i].variant_id == variant_id){
        flagmeta = false;
      }
    }
    if(flagmeta){
      var url_ = config.APIURL + "/order/getSingleMetafield";
      var data = {
        variant_id: variant_id,
        customer_id: cusId,
      };
      var getSingleMetafield = await axios.post(url_, data);
      if(getSingleMetafield.data){
        if(getSingleMetafield.data.status == 'success'){
          tempmetafieldObject.push(getSingleMetafield.data.metafield[0]);
          setmetafieldObject(tempmetafieldObject);
        }
      }
    }
    return tempmetafieldObject;
  }

  const updateOrderObject = async () => {
    //console.log(orderObject);
    //console.log(metafieldObject);
    //console.log(orderObject.line_items.length );
    var updated_total_price = 0, original_total_price = 0, original_total_price_before_discount_ = 0;
    var cartItemsCart = orderObject;
    var lineitem_to_push = [];
    for(var i = 0; i < cartItemsCart.line_items.length; i++){
      var lineitem = cartItemsCart.line_items[i];
      
      //console.log(lineitem.variant_id);
      
      var line_item_metafield = ''; var line_item_metafield_key = '';
      metafieldObject.forEach(obj => {
        if(obj.variantid == lineitem.variant_id){
          line_item_metafield = obj.metafield.value;
          line_item_metafield_key = obj.metafield.key;
        }
      });
      //console.log(line_item_metafield)
      if(line_item_metafield != '' && line_item_metafield_key == 'NormalPricing'){ //Normal Metafield
        //console.log(line_item_metafield.split(','));
        //console.log(lineitem.quantity);

        var allVariantItemQty = lineitem.quantity;
        var quantity = lineitem.quantity;
        var splitTierPricing = line_item_metafield.split(',');
        var original_price = lineitem.price;
        original_total_price += Number(lineitem.price * quantity);

        var Piece = Number(splitTierPricing[6].split('=')[0].split("-")[0]),
        Dozen = Number(splitTierPricing[7].split('=')[0].split("-")[0]),
        Case = Number(splitTierPricing[8].split('=')[0].split("-")[0]);
        var PiecePrice = splitTierPricing[6].split('=')[1],
        DozenPrice = splitTierPricing[7].split('=')[1],
        CasePrice = splitTierPricing[8].split('=')[1];

        if (splitTierPricing[0].trim() == 'percentage') {
        var PiecePriceCut = Number((parseFloat((parseFloat(PiecePrice) / 100) * original_price)).toFixed(2));
        var DozenPriceCut = Number((parseFloat((parseFloat(DozenPrice) / 100) * original_price)).toFixed(2));
        var CasePriceCut = Number((parseFloat((parseFloat(CasePrice) / 100) * original_price)).toFixed(2));
        PiecePrice = Number((original_price - PiecePriceCut).toFixed(2));
        DozenPrice = Number((original_price - DozenPriceCut).toFixed(2));
        CasePrice = Number((original_price - CasePriceCut).toFixed(2));
        } else if (splitTierPricing[0].trim() == 'fixed') {
        PiecePrice = Number((original_price - parseFloat(PiecePrice)).toFixed(2));
        DozenPrice = Number((original_price - parseFloat(DozenPrice)).toFixed(2));
        CasePrice = Number((original_price - parseFloat(CasePrice)).toFixed(2));
        } else if (splitTierPricing[0].trim() == 'fixed_price') {
        PiecePrice = Number((parseFloat(PiecePrice)).toFixed(2));
        DozenPrice = Number((parseFloat(DozenPrice)).toFixed(2));
        CasePrice = Number((parseFloat(CasePrice)).toFixed(2));
        }
       
        var createdOn = splitTierPricing[1].split('-')[0];
        var createdOnId = splitTierPricing[1].split('-')[1];
        var updated_price = 0, updated_line_price = 0;
        var btwPieceAndDozen = false;
        var btwDozenAndCase = false;
        var gtrCase = false;

        var FinalDiscountedPrice = 0;
        if (Piece <= allVariantItemQty && Dozen > allVariantItemQty)        //Piece Condition
        { btwPieceAndDozen = true; }
        else if (Dozen <= allVariantItemQty && Case > allVariantItemQty)    //Dozen Condition
        { btwDozenAndCase = true; }
        else if (Case <= allVariantItemQty)                                 //Case Condition
        { gtrCase = true; }    
        
        // var objMessage = {};
                if(btwPieceAndDozen){ //Piece Price Calculation
                  var isNotInRange = false;
                    FinalDiscountedPrice = (Piece * quantity)  * PiecePrice;
                    updated_price = FinalDiscountedPrice;
                    if (updated_price < 0) { updated_price = 0; }
  
                    var objMessage = [
                      {
                        name: 'Piece Line Price ('+formatMoney(PiecePrice.toFixed(2)) +' ea)',
                        value: formatMoney(updated_price.toFixed(2))
                      },
                    ]; 
                    // objMessage['Piece Line Price ($'+PiecePrice.toFixed(2) +' ea)'] = (updated_price).toFixed(2);                 
                    lineitem.properties = objMessage;

                    updated_line_price = Number((updated_price).toFixed(2));
                    lineitem.updated_line_price = updated_line_price;
                    updated_total_price += updated_line_price;
                }
                else if(btwDozenAndCase){ //Dozen Price Calculation
                    isNotInRange = false;
                  var DQu_ = Number((quantity/Dozen).toString().split('.')[0]);
                  var PRem_ = Number( quantity - (Dozen * DQu_) );


                  var objMessage = [
                    {
                      name: 'Piece Line Price ('+formatMoney(PiecePrice.toFixed(2)) +' ea)',
                      value: formatMoney((Piece * PRem_ * PiecePrice).toFixed(2))
                    },
                    {
                      name: 'Dozen Line Price ('+formatMoney(DozenPrice.toFixed(2)) +' ea)',
                      value: formatMoney((Dozen * DQu_ * DozenPrice).toFixed(2))
                    },
                    {
                      name: 'Piece Qty',
                      value: PRem_
                    },
                    {
                      name: 'Dozen Qty',
                      value: DQu_
                    }
                  ];

                  // objMessage ['Piece Line Price ($'+PiecePrice.toFixed(2) +' ea)'] = (Piece * PRem_ * PiecePrice).toFixed(2);
                  // objMessage['Dozen Line Price ($'+DozenPrice.toFixed(2) +' ea)'] = (Dozen * DQu_ * DozenPrice).toFixed(2);
                  // objMessage['Piece Qty'] = PRem_;
                  // objMessage['Dozen Qty'] = DQu_;
                    var DQu = Number((quantity/Dozen).toString().split('.')[0]);
  
                    FinalDiscountedPrice = ((Dozen * DQu) * DozenPrice) + ( quantity - (Dozen * DQu) ) * PiecePrice;
  
                    lineitem.properties = objMessage;
       
                    updated_price = FinalDiscountedPrice;
                    if (updated_price < 0) { updated_price = 0; }
                    updated_line_price = Number((updated_price).toFixed(2))
                    lineitem.updated_line_price = updated_line_price;
                    updated_total_price += updated_line_price;
                }
                else if(gtrCase){ //Case Price Calculation
                  isNotInRange = false;
  
                  var CQu_ = Number((quantity/Case).toString().split('.')[0]);
                  var DRem_ = Number( quantity - (Case * CQu_) );
                  var DQu_ = Number((DRem_/Dozen).toString().split('.')[0]);
                  var PRem_ = Number( DRem_ - (Dozen * DQu_) );

                  var objMessage = [
                    {
                      name: 'Piece Line Price ('+formatMoney(PiecePrice.toFixed(2)) +' ea)',
                      value: formatMoney((Piece * PRem_ * PiecePrice).toFixed(2))
                    },
                    {
                      name: 'Dozen Line Price ('+formatMoney(DozenPrice.toFixed(2)) +' ea)',
                      value: formatMoney((Dozen * DQu_ * DozenPrice).toFixed(2))
                    },
                    {
                      name: 'Case Line Price ('+formatMoney(CasePrice.toFixed(2)) +' ea)',
                      value: formatMoney((Case * CQu_ * CasePrice).toFixed(2))
                    },
                    {
                      name: 'Piece Qty',
                      value: PRem_
                    },
                    {
                      name: 'Dozen Qty',
                      value: DQu_
                    },
                    {
                      name: 'Case Qty',
                      value: CQu_
                    }
                  ];
                  // objMessage['Piece Line Price ($'+PiecePrice.toFixed(2) +' ea)'] = (Piece * PRem_ * PiecePrice).toFixed(2);
                  // objMessage['Dozen Line Price ($'+DozenPrice.toFixed(2) +' ea)'] = (Dozen * DQu_ * DozenPrice).toFixed(2);
                  // objMessage['Case Line Price ($'+CasePrice.toFixed(2) +' ea)'] = (Case * CQu_ * CasePrice).toFixed(2);
                  // objMessage['Piece Qty'] = PRem_;
                  // objMessage['Dozen Qty'] = DQu_;
                  // objMessage['Case Qty'] = CQu_;
                    var CQu = Number((quantity/Case).toString().split('.')[0]);
                    var CRem = Number( quantity - (Case * CQu) );
                    if(CRem/Dozen == 1){
                        FinalDiscountedPrice = ( (Case * CQu) * CasePrice ) + (Dozen  * DozenPrice);
                    }
                    else if(CRem/Dozen > 1){
                        var DQu = Number((CRem/Dozen).toString().split('.')[0]);
                        FinalDiscountedPrice = ( (Case * CQu) * CasePrice ) + ((Dozen * DQu) * DozenPrice) + ( CRem - (Dozen * DQu) ) * PiecePrice;
                    }
                    else{
                        FinalDiscountedPrice = ( (Case * CQu) * CasePrice ) + CRem * PiecePrice;
                    }
  
                    lineitem.properties = objMessage;
           
                    updated_price = FinalDiscountedPrice;
                    if (updated_price < 0) { updated_price = 0; }
                    updated_line_price = Number((updated_price).toFixed(2));
                    lineitem.updated_line_price = updated_line_price;
                    updated_total_price += updated_line_price;
                  }

        

        
      }
      else if(line_item_metafield != '' && line_item_metafield_key == cusId){ //Customer Metafield
        var allVariantItemQty = lineitem.quantity;
        var quantity = lineitem.quantity;
        var splitTierPricing = line_item_metafield.split(',');
        var original_price = lineitem.price;
        original_total_price += Number(lineitem.price * quantity);

        for (var s = 6; s < splitTierPricing.length; s++) {
          var createdOn = splitTierPricing[1].split('-')[0],
              createdOnId = splitTierPricing[1].split('-')[1],
              allVariantItemQty = quantity;
          var tierRange = splitTierPricing[s].split('=')[0],
              tierPrice = splitTierPricing[s].split('=')[1];
          var minTier = parseInt(tierRange.split("-")[0]),
              maxTier = tierRange.split("-")[1] != 'max' ? parseInt(tierRange.split("-")[1]) : tierRange.split("-")[1];
          var updated_price = 0, condition1 = false, condition2 = false;
        
          if (quantity >= minTier) { condition1 = true; }
          if (maxTier == "max") { condition2 = true; } else if (quantity <= maxTier) { condition2 = true; }
          
          if (condition1 && condition2) {
              var isNotInRange = false;
              if (splitTierPricing[0].trim() == 'percentage') {
                  var originalPriceCut = Number((parseFloat((parseFloat(tierPrice) / 100) * original_price)).toFixed(2));
                  updated_price = Number((original_price - originalPriceCut).toFixed(2));
              } else if (splitTierPricing[0].trim() == 'fixed') {
                  updated_price = Number((original_price - parseFloat(tierPrice)).toFixed(2));
              } else if (splitTierPricing[0].trim() == 'fixed_price') {
                  updated_price = Number((parseFloat(tierPrice)).toFixed(2));
              }
            

              if (updated_price < 0) { updated_price = 0; }
              updated_line_price = Number((updated_price * quantity).toFixed(2))
              lineitem.updated_line_price = updated_line_price;

              var objMessage = [
                { name: 'Special Line Price ('+formatMoney(updated_price.toFixed(2)) +' ea)',
                  value: formatMoney(updated_line_price.toFixed(2)) }
              ];
            
              updated_total_price += updated_line_price;

              lineitem.properties = objMessage;

             // UpdateCartItem(quantity, true, isUpdate, updated_price, updated_line_price, index, variant_id, original_price, original_line_price, isNotInRange, cartItems[index].product_id);
          }
          else{
            if(!lineitem.objMessage){
              
              var objMessage = [
                { name: 'Piece Line Price ('+ formatMoney(original_price) +' ea)',
                  value: formatMoney((original_price * quantity).toFixed(2)) }
              ];
            
              lineitem.properties = objMessage;
            }
          }
        }

      }
      else{ //Non tiered items
        // original_total_price += Number(lineitem.price * quantity);
        updated_total_price += Number(lineitem.price * quantity);
      } //metafield calculations end

    lineitem_to_push.push(lineitem);

    original_total_price_before_discount_ += Number(lineitem.price * quantity);

    } //line item for
    
    var pricediff_ = original_total_price - updated_total_price;

    if(pricediff_ != 0){
      cartItemsCart.discount_codes = [{
        "code": "Wholesale Discount",
        "amount": pricediff_,
        "type": "fixed_amount"        
      }];
      setpricediff(pricediff_);
    }
    else{
      setpricediff(0)
    }
    cartItemsCart.line_items = lineitem_to_push;
    cartItemsCart.subtotal_price = updated_total_price.toFixed(2);
    setoriginal_total_price_before_discount(original_total_price_before_discount_);
    setorderObject(cartItemsCart);
    setCustomerList(lineitem_to_push);
    
  } //func orderObject end

  const createLineItem = async () => {
    setisLineItemEdit(true);
    var metafieldObject_ = await getmetafieldCall(htmlSelection[0].id);
    
    var discountCodeDigit = "", characters = '0123456789';
    for (var r = 0; r <= 10; r++) {
      discountCodeDigit += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    // //console.log(discountCodeDigit)
    var cc = htmlSelection[0]
    var c = {};
    c.id = Number(discountCodeDigit);
    c.name = cc.title.split('|')[0] != null ? cc.title.split('|')[0] : '' ;
    c.price = cc.price;
    c.quantity = value;
    c.total = Number(cc.price) * Number(value);
    c.variant_id = cc.id;
    c.variant_title = cc.title.split('|')[1] != null ? cc.title.split('|')[1] : '' ;
    c.properties = cc.properties != null ? cc.properties : null ;
    c.sku = cc.title.split('|')[2] != null ? cc.title.split('|')[2] : '' ;

    
    var tempOrderObject = orderObject;
    var li = tempOrderObject.line_items;
    li.unshift(c);

    tempOrderObject.line_items = li;
    setorderObject(tempOrderObject);
    setCustomerList(li);

    //console.log('\\\\\\\\\\\\');
    updateOrderObject();
    //console.log('\\\\\\\\\\\\');
    // setCustomerList(tempList); //setting line item list
    handleChange_create(); //close modal
  };
  const[editModalVar,setEditModalVar]=useState('')
  const handleEdit = (id, name, price, quantity, variant_id, variant_title, sku, properties) => {
    //set update modal
   var c={
      name:name,
      price:price

    }
    //console.log(quantity)
    setActive_update(true)
    setqtyValue(quantity.toString())
    setEditModalVar(c)
    setIdToEdit(id)
    // //console.log(variant_id);
    // //console.log(price);
    // //console.log(quantity);
    // //console.log(cusId);
    //console.log(metafieldObject);
    var tempmetafieldObject = metafieldObject;
    var flagmeta = false;
    for(var i = 0; i < metafieldObject.length; i++){
      if(metafieldObject[i].variant_id == variant_id){
        flagmeta = true;
      }
    }
    if(flagmeta){
      var url_ = config.APIURL + "/order/getSingleMetafield";
      var data = {
        variant_id: variant_id,
        price: price,
        quantity: quantity,
        customer_id: cusId,
      };
      axios
      .post(url_, data)
      .then((result) => {
        //console.log(result);
        tempmetafieldObject.push(result.data.metafield[0]);
        //console.log('<><><><><><>');
        //console.log(tempmetafieldObject);
        setmetafieldObject(tempmetafieldObject);
      })
      .catch((error) => {
        //console.log(error);
      });        
    }
  };
  //product add modal 

  const [productSearchTextField, setproductSearchTextField] = useState();

  const [typingTimer, setTypingTimer] = useState();
  const handleproductSearchChange = useCallback(async (newValue) => {
    setproductSearchTextField(newValue);
    clearTimeout(typingTimer);
    if (newValue) {
      const typingTimer_ = setTimeout(async () => {
        doneTyping(newValue)
      }, 2000);
      setTypingTimer(typingTimer_);
    }
  });
  //Product search textbox function with ""Click"" event
  const handleproductSearchClick = async (newValue) => {

    var productType = "variants"; //Get Products list here
    var productSearch = await axios.get(
      config.APIURL + "/tier/ProductSearchByGraphQL?productType=" + productType + "&customerId=" + cusId
    );
    //finals products array
    // if(selected != "select"){
    if (productType == "variants") {
      var data = productSearch.data.finalProductsArray;
      var ddt = [];
      var dLength = data.length;
      for (var x = 0; x < data.length; x++) {
        var cc = data[x];
        var c = {};
        c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
        if (cc.sku != null && cc.sku != "") {
          c.title = cc.product.title + ' | ' + cc.title + ' | ' + cc.sku;
          c.handle = cc.sku;
        }
        else {
          c.title = cc.product.title + ' | ' + cc.title;
          c.handle = '';
        }
        c.price = cc.price;
        ddt.push(c);
      }
      setProductList(ddt);
    }
    // }
    // else{
    //   setproductSearchTextField("Select Product Identifier");
    //   setTimeout(async () => {
    //     setproductSearchTextField("");
    //   }, 1000);
    // }

  };
  //Variants Search by field
  const [variantSearchIdentifier, setVariantSearchIdentifier] = useState("Search by Product Title");
  const variantOptions = [
    <option key="vTitle" value="Search by Product Title" label="Search by Product Title" ></option>,
    <option key="pTitle" value="Search by Variant Title" label="Search by Variant Title" ></option>,
    <option key="vSku" value="Search by SKU" label="Search by SKU" ></option>
  ];
  //Product Search Clear Textfield ""Click"" event
  const handleProductSearchClearButtonClick = useCallback(() => setproductSearchTextField(""), []);

  let [htmlSelection, setHtmlSelection] = useState([]);
  const handleRemoveItem = (id) => {
    setHtmlSelection(htmlSelection.filter((item) => item.id !== id));
  };

  const [productList, setProductList] = useState("");
  //products selected field
  const handleResourceItem = (id, title, price, handle) => {
    if (htmlSelection.length == 0) {
      setHtmlSelection((oldArray) => [
        // ...oldArray,
        { id, title, price, handle }]);
    } else {
      var flag = false;
      htmlSelection.filter((item) => {
        if (item.id == id) {
          flag = true;
        }
      });
      if (!flag) {
        setHtmlSelection((oldArray) => [
          // ...oldArray,
          { id, title, price, handle }]);
      }
    }
    handleProductCustomerListHide()
  };

  const doneTyping = async (newValue) => {
    setproductSearchTextField(newValue);
    if (newValue != null && newValue != "") {
      // if (selected != "select") {
      var productType = "variants"; //finals products array
      if (productType == "variants") {
        var variantSearchBy = '';
        if (variantSearchIdentifier == 'Search by Variant Title') { variantSearchBy = 'vTitle' }
        else if (variantSearchIdentifier == 'Search by Product Title') { variantSearchBy = 'pTitle' }
        else { variantSearchBy = 'vSku' }
        var productSearch = await axios.get(config.APIURL + "/tier/ProductSearchByGraphQL?productType=" + productType + "&query=" + newValue + "&variantSearchby=" + variantSearchBy + "&customerId=" + cusId);
        if (variantSearchBy == 'pTitle') {
          var data = productSearch.data.finalProductsArray;
          var ddt = [];
          var dLength = data.length;
          for (var x = 0; x < data.length; x++) {
            var cc = data[x];
            var c = {};
            c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
            if (cc.sku != null && cc.sku != "") {
              c.title = cc.product.title + ' | ' + cc.title + ' | ' + cc.sku;
              c.handle = cc.sku;
            }
            else {
              c.title = cc.product.title + ' | ' + cc.title;
              c.handle = '';
            }
            c.price = cc.price;
            ddt.push(c);
          }
        }
        else {
          var data = productSearch.data.finalProductsArray;
          var ddt = [];
          var dLength = data.length;
          for (var x = 0; x < data.length; x++) {
            var cc = data[x];
            var c = {};
            c.id = cc.id.substring(cc.id.lastIndexOf("/") + 1);
            if (cc.sku != null && cc.sku != "") {
              c.title = cc.product.title + ' | ' + cc.title + ' | ' + cc.sku;
              c.handle = cc.sku;
            }
            else {
              c.title = cc.product.title + ' | ' + cc.title;
              c.handle = '';
            }
            c.price = cc.price;
            ddt.push(c);
          }
        }
        setProductList(ddt);
      }
      // } else {
      //   setproductSearchTextField("Select Product Identifier");
      //   setTimeout(async () => {
      //     setproductSearchTextField("");
      //   }, 1000);
      // }

    }
    else {
      handleproductSearchClick();
    }
  }
  const handleVariantSearchChange = useCallback((e) => {
    setVariantSearchIdentifier(e.target.value);
  }, []);
  const handleProductCustomerListHide = () => { //All List hide function
    setProductList("");
    // setCustomerList("");
  };
  const [value, setValue] = useState("1");

  const handleChange = useCallback((newValue) => {
    if (newValue >= 1) {
      setValue(newValue)

    }
  },
    []);
    

  const handleChangeQtyValue = (newValue) => {
    //console.log(newValue)
    if (newValue >= 1) {
      setqtyValue(newValue)

    }
  };

    const [checkedCheckbox, setcheckedCheckbox] = useState(true);
    const handleNotificationCheckbox = useCallback((newChecked) => setcheckedCheckbox(newChecked), []);

    const handlesubmit = async () => {
      if(isLineItemEdit){
        // alert("Order  Submitted")
        setloadingsubmit(true);
        let url = config.APIURL + '/order/updatebydraftorder';
        let data = {orderobject:orderObject, notifyCustomer: checkedCheckbox  };
        // axios.post(url, data)
        //   .then(res => {
        //     //console.log(res.data)
           
        //   })
        //   .catch((error) => {
        //     //console.log(error);
        //   })
      var ordercreate = await axios.post(url, data);
      if(ordercreate){
        if(ordercreate.status == 200){
          setloadingsubmit(false);
          setSuccessActive(true);
          setSuccessToggleMesssage("Order details updated");
          window.location.href = "/orderdetail/" +  ordercreate.data.id;
        }
      }                
    }    
  } 
    const handleNotes = async () => {    
      setloadingEditNotes(true);
      let url = config.APIURL + '/order/updateorderdetails';
      let data = {order_id:orderObject.id, "orderobject":{ "notes": notes } };
    axios.post(url, data)
      .then(res => {
        ////console.log(res.data)
      setActiveNotes(false)
        var localorderObject=orderObject;
        localorderObject.note=notes;
        setorderObject(localorderObject)
        setloadingEditNotes(false);
        setSuccessActive(true);
  	    setSuccessToggleMesssage("Note saved");
      })
      .catch((error) => {
        //console.log(error);
        setloadingEditNotes(false);
        seterrorActive(true);
  	    seterrorToggleMesssage("Something went wrong, Please try again later");
      });
    }

    const handleContactInfo = async () => {
      // alert(contactInfo)
      setloadingContactInfo(true);
      let url = config.APIURL + '/order/updateorderdetails';
      let data = { order_id:orderObject.id, orderobject:{ "contactinfo":{ "email":contactInfo}} };
      axios.post(url, data)
        .then(res => {
          var localorderObject=orderObject;
          localorderObject.email=contactInfo;
          setorderObject(localorderObject)
          setActiveContactInfo(false)
          setloadingContactInfo(false);
          setSuccessActive(true);
  	      setSuccessToggleMesssage("Contact information updated");
        })
        .catch((error) => {
          //console.log(error);
          setloadingContactInfo(false);
          seterrorActive(true);
  	      seterrorToggleMesssage("Something went wrong, Please try again later");
        });     
    }
   
    const handleBillingAddress = async () => {
      // alert("Billing Address Edit (Open Modal)")
    }

    //#region submit modal
    const [activeSubmitModal, setactiveSubmitModal] = useState(false);
    const handleSubmitModal = useCallback(() => {
      // if(activeSubmitModal){ setNotes(orderObject.note) }
     
      setactiveSubmitModal(!activeSubmitModal)}, [activeSubmitModal]);    
    //#endregion

    //#region edit modal
    const [activeNotes, setActiveNotes] = useState(false);
    const handleNotesModal = useCallback(() => {
      if(activeNotes){ setNotes(orderObject.note) }
     
      setActiveNotes(!activeNotes)}, [activeNotes]);
    const [notes, setNotes] = useState("");
    const handleChangeNotes = useCallback((newValue) => setNotes(newValue), []);
    //#endregion



  //#region CONTACT INFORMATION modal
  const [activeContactInfo, setActiveContactInfo] = useState(false);
  const handleContactInfoModal = useCallback(() => {
    if(activeContactInfo){  setContactInfo(orderObject.email)    }
    setActiveContactInfo(!activeContactInfo)}, [activeContactInfo]);   
   const [contactInfo, setContactInfo] = useState('');
  const handleContactInfoValue = useCallback((newValue) => setContactInfo(newValue), []);
  //#endregion


   //#region CONTACT INFORMATION modal
   const [activeShipAddress, setActiveShipAddress] = useState(false);
   const handleShipAddressModal = useCallback(() =>
   {
     if(activeShipAddress){
    setFirstName(orderObject.shipping_address.first_name);  
    setLastName(orderObject.shipping_address.last_name);  
    setCompany(orderObject.shipping_address.company);  
    setAddress(orderObject.shipping_address.address1);
    setApparment(orderObject.shipping_address.address2);    
    setCountry(orderObject.shipping_address.country);  
    setState(orderObject.shipping_address.province);  
    setCity(orderObject.shipping_address.city);  
    setZip(orderObject.shipping_address.zip);  
    setPhone(orderObject.shipping_address.phone);  
   }
   
   setActiveShipAddress(!activeShipAddress)}
   
   , [activeShipAddress]);   
    const [firstName, setFirstName] = useState('');  
    const [lastName, setLastName] = useState('');  
    const [company, setCompany] = useState('');  
    const [address, setAddress] = useState('');
    const [apparment, setApparment] = useState('');    
    const [country, setCountry] = useState('');  
    const [state, setState] = useState('');  
    const [city, setCity] = useState('');  
    const [zip, setZip] = useState('');  
    const [phone, setPhone] = useState('');
   const handleShippingValue =(newValue,functName) => {
   functName(newValue)
   };
   const [addressCheck, setaddressCheck] = useState(false);  
   const [lastNameCheck, setlastNameCheck] = useState(false);
   const [zipCheck, setzipCheck] = useState(false);
   const [cityCheck, setcityCheck] = useState(false);
   //#endregion
  const handleShippingAddress = async () => {
    setloadingShippingAddress(true);
    if( address == null || address == ""){setaddressCheck(true)}else{setaddressCheck(false)};
    if( lastName == null || lastName == ""){setlastNameCheck(true)}else{setlastNameCheck(false)};
    if( zip == null || zip == ""){setzipCheck(true)}else{setzipCheck(false)};
    if( city == null || city == ""){setcityCheck(true)}else{setcityCheck(false)};
    if(address != null || address != ""&& lastName != null || lastName != ""&& zip != null || zip != ""&& city != null || city != ""){
      // alert("Shipping Address Edit")
      let url = config.APIURL + '/order/updateorderdetails';
        
      let data = {order_id:orderObject.id,
        orderobject:{
      "shippingaddress": {
      "first_name": firstName,
      "last_name": lastName,
      "address":address,
      "apartment": apparment,
      "country": country,
      "state": state,
      "city": city,
      "company": company,
      "zip": zip,
      "phone": phone
      
    }}};
    axios.post(url, data)
      .then(res => {
        //console.log(res.data)
       var shipping_address ={
          "first_name": firstName,
          "last_name": lastName,
          "address1":address,
          "address2": apparment,
          "country": country,
          "province": state,
          "city": city,
          "company": company==""?null:company,
          "zip": zip,
          "phone": phone==""?null: phone        
        }
        var localorderObject=orderObject;
        localorderObject.shipping_address=shipping_address;
        setorderObject(localorderObject)
        //console.log("localorderObject")
        //console.log(localorderObject)
        setActiveShipAddress(false)
        setloadingShippingAddress(false);
        setSuccessActive(true);
  	    setSuccessToggleMesssage("Address saved");
      })
      .catch((error) => {
        //console.log(error);
        setloadingShippingAddress(false);
        seterrorActive(true);
  	    seterrorToggleMesssage("Something went wrong, Please try again later");
      })
    
    }
   
   
  }
   const validateError = <InlineError message="Required Field" />;

  

  return (
    <Frame>
    <div className="OrderDetailPageWidth">
      <Page fullWidth breadcrumbs={[{content: 'Order List', url: '/orderlist'}]} title={"Order " + orderName}
        primaryAction={{
          onAction:handleSubmitModal,
          content: /*loadingsubmit ? <Spinner accessibilityLabel="Spinner example" size="small" color="inkLightest" />: */<p>Update order</p>,
          disabled: isLineItemEdit == false ? true : false,
        }}>  
        {loadingMarkup}
      
        <Layout>
        <Layout.Section oneHalf>
        <Card>

        <Modal //for submit confirm
          open={activeSubmitModal}
          onClose={handleSubmitModal}
          title="Update Order Details"
          primaryAction={{
            content: loadingsubmit ? <Spinner accessibilityLabel="Spinner example" size="small" color="inkLightest" />:"Update",
            onAction: handlesubmit,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleSubmitModal,
            },
          ]}
        >
        <Modal.Section>
          
          <label style={{display:'block'}}>Are you sure you want to save the current changes?</label>
          <label style={{display:'block'}}>On clicking the update button, The new order will be created in the shopify orders section. Any paid amount will be refunded to the customer. You need to manually collect payment for the newly created order.</label>
          {/* <label style={{display:'block'}}>Any paid amount will be refunded to the customer. You need to manually collect payment for the newly created order.</label> */}
          <Checkbox label="Send notification to customer" checked={checkedCheckbox} onChange={handleNotificationCheckbox} />
          
        </Modal.Section>
        </Modal>

        <Modal // for edit
          
            open={activeNotes}
            onClose={handleNotesModal}
            title="Edit Notes"
            primaryAction={{
              content: loadingEditNotes ? <Spinner accessibilityLabel="Spinner example" size="small" color="inkLightest" />:"Save",
              onAction: handleNotes,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: handleNotesModal,
              },
            ]}
          >
            <Modal.Section>
            <TextField label="Edit :" value={notes} onChange={handleChangeNotes} />
            
            </Modal.Section>
        </Modal>
          <Modal // for contact info
          
            open={activeContactInfo}
            onClose={handleContactInfoModal}
            title="Edit Email"
            primaryAction={{
              content: loadingContactInfo ? <Spinner accessibilityLabel="Spinner example" size="small" color="inkLightest" />:"Save",
              onAction: handleContactInfo,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: handleContactInfoModal,
              },
            ]}
          >
            
            <Modal.Section>
            <TextField label="Email :" type="email" value={contactInfo} onChange={handleContactInfoValue} />  
            </Modal.Section>
          </Modal>
          <Modal // for shipping address
          
          open={activeShipAddress}
          onClose={handleShipAddressModal}
          title="Edit shipping address"
          primaryAction={{
            content: loadingShippingAddress ? <Spinner accessibilityLabel="Spinner example" size="small" color="inkLightest" />:"Save",
            onAction: handleShippingAddress,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleShipAddressModal,
            },
          ]}
        >
           <Layout>
          
          <Layout.Section oneThird> 
          <TextField label="First name :" value={firstName} onChange={(e)=>{handleShippingValue(e,setFirstName)} }/>
          </Layout.Section >
          <Layout.Section oneThird>
          <TextField label="Last name :" value={lastName}  onChange={(e)=>{handleShippingValue(e,setLastName)}} />
          <div
                    className="TexxtFieldValidation"
                    style={{
                      display: lastNameCheck ? "block" : "none",
                    }}
                  >
                    {" "}
                    {validateError}{" "}
                  </div>{" "}
          </Layout.Section >
          <Layout.Section >
          <TextField label="Company :" value={company} onChange={(e)=>{handleShippingValue(e,setCompany)}} />
          </Layout.Section >
          <Layout.Section >
          <TextField label="Address :" value={address}  onChange={(e)=>{handleShippingValue(e,setAddress)}} />
          <div
                    className="TexxtFieldValidation"
                    style={{
                      display: addressCheck ? "block" : "none",
                    }}
                  >
                    {" "}
                    {validateError}{" "}
                  </div>{" "}
          </Layout.Section >
          <Layout.Section >
          <TextField label="Apartment, suite, etc. :" value={apparment}  onChange={(e)=>{handleShippingValue(e,setApparment)}} />
          </Layout.Section >
          
          <Layout.Section oneThird>
          <TextField  readOnly label="Country/Region :" value={country}  onChange={(e)=>{handleShippingValue(e,setCountry)}} />
          </Layout.Section >
          <Layout.Section oneThird>
          <TextField readOnly label="State :" value={state}  onChange={(e)=>{handleShippingValue(e,setState)}} />
          </Layout.Section >
          <Layout.Section oneThird>
          <TextField  label="City :" value={city}  onChange={(e)=>{handleShippingValue(e,setCity)}} />
          <div
                    className="TexxtFieldValidation"
                    style={{
                      display: cityCheck ? "block" : "none",
                    }}
                  >
                    {" "}
                    {validateError}{" "}
                  </div>{" "}
          </Layout.Section >
          <Layout.Section oneThird>
          <TextField label="zip :" value={zip}  onChange={(e)=>{handleShippingValue(e,setZip)}} />
          <div
                    className="TexxtFieldValidation"
                    style={{
                      display: zipCheck ? "block" : "none",
                    }}
                  >
                    {" "}
                    {validateError}{" "}
                  </div>{" "}
          </Layout.Section >
          <Layout.Section >
          <TextField label="Phone:" type="number" value={phone}  onChange={(e)=>{handleShippingValue(e,setPhone)}} />
          </Layout.Section >
          <Layout.Section ></Layout.Section>
          </Layout>
        
        </Modal>
          <Modal // for delete
            open={active_}
            onClose={handleChange_}
            title="Confirm Delete"
            primaryAction={{
              content: "Delete",
              onAction: deleteLineItem,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: handleChange_,
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                <p>Are you sure you want to delete?</p>
              </TextContainer>
            </Modal.Section>
          </Modal>

          <Card.Section>
          <Stack distribution="fill">
          <button className="Polaris-Button" type="button" onClick={redirect} >
            <span className="Polaris-Button__Content">
                Add product to the order
            </span>
          </button>
          </Stack>
            <div className="Polaris-ResourceItem__Container">
              <table style={{ width: "100%" }}>
                <thead className="table-thead">
                  <tr>
                    <td className="orderDetail__productColumn">Product</td>
                    <td className="orderDetail__unitPriceColumn">Unit Price</td>
                    <td className="orderDetail__quantityColumn">Qty</td>
                    <td className="orderDetail__totalColumn">Total</td>
                    <td className="orderDetail__deleteColumn">Actions</td>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div id="orderdetailpageresourcelist">
            {progressLoading ? 
          <Stack distribution="center"><Spinner accessibilityLabel="Spinner example" size="large" color="highlight" /> </Stack>
          :
            <ResourceList
              resourceName={{ singular: "customer", plural: "customers" }}
              items={customerList}
              renderItem={(item) => {
                const { id, name, price, updated_line_price, quantity, variant_id, variant_title, sku, properties } = item;
            
                return (
                  <ResourceItem id={id} className="orderDetail__row">
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="orderDetail__productColumn">
                            {name}
                            <p style={{ display: "block", color: "rgb(132, 144, 157)", fontSize: " 0.9em", textAlign: "left" }}>{variant_title}</p>
                            <p style={{ display: "block", color: "rgb(132, 144, 157)", fontSize: " 0.9em", textAlign: "left" }}>{sku}</p>
                            {properties != null ? (
                              <div className="">
                                {properties.length > 0
                                  ? properties.map((item, i) => (
                                    <p key={i} style={{ display: "block", color: "rgb(132, 144, 157)", fontSize: " 0.9em", textAlign: "left" }}>{item.name + " : " } { item.value != null ? <span className="boldtext">{item.value}</span> : item.value}</p>

                                  ))
                                  : null}
                              </div>

                            ) : null}


                          </td>
                          <td className="orderDetail__unitPriceColumn">
                            {formatMoney(price)}
                          </td>
                          <td className="orderDetail__quantityColumn">
                          <Stack vertical spacing="tight">
                            <Stack.Item >
                              {quantity}
                              {/* <input
                              className="orderDetail__quantityColumn1"
                              type="number"
                              value={quantity}
                              onChange={(e) => {
                                textFieldChange(
                                  e.target.value,
                                  id,
                                  name_,
                                  price,
                                  quantity,
                                  variant_id
                                );
                              }}
                            >  
                            </input> */}
                            </Stack.Item>
                            {/* <Stack.Item>
                            
                            <button onClick={() => handleEdit(variant_id, price, quantity) } className="Polaris-Button Polaris-Button--plain" type="button"><span className="Polaris-Button__Content"><span className="Polaris-Button__Text">Edit</span></span></button>
                            </Stack.Item> */}
                          </Stack>
                          </td>
                         
                          <td className="orderDetail__totalColumn">
                          {updated_line_price != null ? 
                            <div>
                            <span className="lineitemstrike">{formatMoney((Number(price) * quantity).toFixed(2))}</span>
                            <span className="boldtext">{updated_line_price != null ? formatMoney(updated_line_price.toFixed(2)) : null}</span>
                            </div>
                            :
                            <span>{formatMoney((Number(price) * quantity).toFixed(2))}</span>
                            }
                          </td>
                          <td className="orderDetail__deleteColumn">
                            {" "} 
                            <span
                             onClick={() => handleEdit(id, name, price, quantity, variant_id, variant_title, sku, properties) }
                              style={{ paddingLeft: "20px", cursor:'pointer' }}
                              title="edit"
                            >
                              <i className="fas fa-edit fa-1.8x"></i>
                            </span>
                            <span
                              onClick={() => {
                                handleChange_(id);
                              }}
                              style={{ paddingLeft: "20px", cursor:'pointer' }}
                              title="Close"
                            >
                              <i className="fas fa-close fa-1.8x"></i>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* </Stack> */}
                  </ResourceItem>
                );
              }}
            // selectable
            />}
            </div>
          </Card.Section>

          {/* <Card.Section>
            <Stack>
              <Stack.Item fill>
                <Heading>Discount</Heading>
              </Stack.Item>
              <Stack.Item>
                <Badge>Paid</Badge>
              </Stack.Item>
              <Stack.Item>
                <Badge>Fulfilled</Badge>
              </Stack.Item>
            </Stack>
          </Card.Section> */}


        </Card>

        </Layout.Section>
        
        <Layout.Section oneThird>
              <Card title="Summary">
                <Card.Section>
                  <Stack distribution="fillEvenly">
                    
                    
                    <Stack.Item >
                      <Stack>
                        <Stack.Item fill><label>Original Total</label></Stack.Item>
                        <Stack.Item>
                          { orderoriginaltotal != orderObject.subtotal_price ?
                          <label style={{color:'lightslategray', textDecorationLine:'line-through'}}>{ orderoriginaltotal != null ? formatMoney(orderoriginaltotal) : 0}</label>
                          : <label style={{color:'lightslategray'}}>{ orderoriginaltotal != null ? formatMoney(orderoriginaltotal) : 0}</label>}
                          </Stack.Item>
                      </Stack>  
                    </Stack.Item>
                    
                    { pricediff != 0 && pricediff !== undefined ?
                    <Stack.Item >
                      <Stack>
                        <Stack.Item fill><label>Wholesale Discount</label></Stack.Item>
                        <Stack.Item><label>{ pricediff != 0 ? "-" + formatMoney(pricediff.toFixed(2)) : 0}</label></Stack.Item>
                      </Stack>  
                    </Stack.Item >  
                    : null }
                    
                    <Stack.Item >
                      <Stack>
                        <Stack.Item fill><label>Updated Total</label></Stack.Item>
                        <Stack.Item>
                          <label>{ orderObject.subtotal_price != null && orderObject.subtotal_price !== undefined 
                          ? 
                          <span>
                            { original_total_price_before_discount != 0 ? <span style={{textDecorationLine:'line-through', marginRight:'5px'}}>{formatMoney(original_total_price_before_discount.toFixed(2))}</span> : null}
                            <span className="boldtext">{formatMoney(orderObject.subtotal_price)} </span>
                            </span>
                          : 0}
                          </label>
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>                
                  </Stack>
                </Card.Section>
                {/* <Card.Section>
                  <Stack>
                    <Stack.Item fill><strong>Amount to refund</strong></Stack.Item>
                    <Stack.Item><label>{ orderObject.subtotal_price != null ? formatMoney( Number( orderObject.subtotal_price - 1000 ).toFixed(2)) : 0}</label></Stack.Item>
                  </Stack>
                </Card.Section> */}
                {/* <Card.Section>
                  <Stack vertical>
                    <Stack.Item><label>NOTIFICATION</label></Stack.Item>
                    <Stack.Item><Checkbox label="Send notification to customer" checked={checkedCheckbox} onChange={handleNotificationCheckbox} /></Stack.Item>
                  </Stack>
                </Card.Section> */}
                {/* <Card.Section>
                  <Stack distribution="center">
                    <Stack.Item><Button onClick={handlesubmit} primary>Update order</Button></Stack.Item>
                  </Stack>
                </Card.Section> */}
              </Card>
              
              <Card title="Notes" actions={[{content: 'Edit', onAction: () =>handleNotesModal(),}]}>
                <Card.Section>               
                  { notes != null && notes != "" ? <p>{notes}</p> : null }
                </Card.Section>
              </Card>

              <Card title="Customer">
              {customername!=''?<p style={{marginLeft: "20px", marginTop: "10px",fontSize: "1em", fontWeight: "400"}}>{customername}</p> :null }              
                <Card.Section>
                  <Stack vertical>
                    <Stack.Item>
                        <Stack>
                          <Stack.Item fill><label>CONTACT INFORMATION</label></Stack.Item>
                         
                          <Stack.Item><Button onClick={() => handleContactInfoModal()} plain>Edit</Button></Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item> {contactInfo!=''?<p style={{margin: "2px",fontSize: "1em", fontWeight: "400"}}>{contactInfo}</p> :null }              
              </Stack.Item>
                  </Stack>
                </Card.Section>
                <Card.Section>
                <Stack vertical>
                    <Stack.Item>
                        <Stack>
                          <Stack.Item fill><label>SHIPPING ADDRESS</label></Stack.Item>
                          <Stack.Item><Button onClick={() => handleShipAddressModal()} plain>Edit</Button></Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
       {lastName!=''?<p >{firstName==''?lastName:firstName+" "+ lastName}</p> :null }              
       {company!=''?<p>{company}</p> :null }              
       {address!=''?<p >{address}</p> :null }              
       {apparment!=''?<p >{apparment}</p> :null }              
       {city!=''?<p >{city +" "+ state+ " "+zip}</p> :null }              
       {country!=''?<p >{country }</p> :null }              
       {phone!=''?<p >{phone }</p> :null }              
       
                    </Stack.Item>
                  </Stack>
                </Card.Section>
                <Card.Section>
                <Stack vertical>
                    <Stack.Item>
                        <Stack>
                          <Stack.Item fill><label>BILLING ADDRESS</label></Stack.Item>
                          {/* <Stack.Item><Button onClick={() => handleBillingAddress()} plain>Edit</Button></Stack.Item> */}
                        </Stack>
                    </Stack.Item>
                    {orderObject!=''?
                    <Stack.Item>
      
       {orderObject.billing_address.last_name!=''?<p >{orderObject.billing_address.first_name==''?orderObject.billing_address.last_name:orderObject.billing_address.first_name+" "+ orderObject.billing_address.last_name}</p> :null }              
       {orderObject.billing_address.company!=''?<p>{orderObject.billing_address.company}</p> :null }              
       {orderObject.billing_address.address1!=''?<p >{orderObject.billing_address.address1}</p> :null }              
       {orderObject.billing_address.address2!=''?<p >{orderObject.billing_address.address2}</p> :null }              
       {orderObject.billing_address.city!=''?<p >{orderObject.billing_address.city +" "+ orderObject.billing_address.province+ " "+orderObject.billing_address.zip}</p> :null }              
       {orderObject.billing_address.country!=''?<p >{orderObject.billing_address.country }</p> :null }              
       {orderObject.billing_address.phone!=''?<p >{orderObject.billing_address.phone }</p> :null }     
                    
                    </Stack.Item>
                    :null}
                  
                    </Stack>
                </Card.Section>
              </Card>
        </Layout.Section>

        </Layout>

        <Modal  //for create
          open={active_update}
          onClose={handleChange_update}
          title="Update Product"
          primaryAction={{
            content: loadingUpdateProduct ? <Spinner accessibilityLabel="Spinner example" size="small" color="inkLightest" />:"Update",
            onAction: updateLineItem,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleChange_update,
            },
          ]}
        >
          <Modal.Section>
          <table style={{ width: "100%" }}>
                          {/* <thead>
                        <tr>
                          <th className="orderDetail__productColumn">
                          Title
                          </th>
                          <th>Price</th>
                          <th>Qty</th>
                          <th>Total</th>
                          </tr>
                          </thead> */}
                          <tbody>
                            <tr>
                              <td className="orderDetail__productColumn">
                                {editModalVar.name}
                              </td>
                              <td>{formatMoney(editModalVar.price)}</td>
                              <td style={{ width: "33%" }}>
                                <div style={{ width: '50%' }}>
                                  <TextField
                                    type="number"
                                    value={qtyValue}
                                    onChange={handleChangeQtyValue}
                                  />
                                </div>
                              </td>
                              <td>{formatMoney(Number(editModalVar.price * qtyValue).toFixed(2))}</td>
                              <td><button
                                type="button"
                                onClick={() => handleRemoveItem("id")}
                                aria-label={"ids"}
                                className="Polaris-Tag__Button"
                              >
                               
                              </button></td>
                            </tr>
                          </tbody>
                        </table>
          </Modal.Section>
        </Modal>

        <Modal  //for create
          open={active_create}
          onClose={handleChange_create}
          title="Add Product"
          primaryAction={{
            content: "Add ",
            onAction: createLineItem,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleChange_create,
            },
          ]}
        >
          <Modal.Section >
            <TextContainer >
              {/* Product Section */}
             
                <TextField
                  label=""
                  value={productSearchTextField}
                  onChange={handleproductSearchChange}
                  onFocus={handleproductSearchClick}
                  autoComplete="off"
                  placeholder="Click to show list ↴"
                  clearButton

                  connectedRight={
                    "variants" == 'variants' ?
                      <div className="">
                        <div className="Polaris-Select" value={variantSearchIdentifier}>
                          <select
                            onChange={handleVariantSearchChange}
                            id="PolarisSelect1"
                            className="Polaris-Select__Input"
                            aria-invalid="false"
                            defaultValue={variantSearchIdentifier}
                          >
                            {variantOptions}
                          </select>
                          <div
                            className="Polaris-Select__Content"
                            aria-hidden="true"
                          >
                            <span className="Polaris-Select__SelectedOption">
                              {variantSearchIdentifier}
                            </span>
                            <span className="Polaris-Select__Icon">
                              <span className="Polaris-Icon">
                                <svg
                                  viewBox="0 0 20 20"
                                  className="Polaris-Icon__Svg"
                                  focusable="false"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M13 8l-3-3-3 3h6zm-.1 4L10 14.9 7.1 12h5.8z"
                                    fillRule="evenodd"
                                  ></path>
                                </svg>
                              </span>
                            </span>
                          </div>
                          <div className="Polaris-Select__Backdrop"></div>
                        </div>
                      </div>
                      : null
                  }
                  onClearButtonClick={handleProductSearchClearButtonClick}
                />
           
                {htmlSelection.length > 0 ? (
                  <div className="productSeletionBarContainer">
                    {htmlSelection.length > 0
                      ? htmlSelection.map((item, i) => (
                        <table style={{ width: "100%" }}>
                          {/* <thead>
                        <tr>
                          <th className="orderDetail__productColumn">
                          Title
                          </th>
                          <th>Price</th>
                          <th>Qty</th>
                          <th>Total</th>
                          </tr>
                          </thead> */}
                          <tbody>
                            <tr>
                              <td className="orderDetail__productColumn">
                                {item.title}
                              </td>
                              <td>{formatMoney(item.price)}</td>
                              <td style={{ width: "33%" }}>
                                <div style={{ width: '50%' }}>
                                  <TextField
                                    type="number"
                                    value={value}
                                    onChange={handleChange}
                                  />
                                </div>
                              </td>
                              <td>{formatMoney((item.price * value).toFixed(2))}</td>
                              <td><button
                                type="button"
                                onClick={() => handleRemoveItem(item.id)}
                                aria-label={item.id}
                                className="Polaris-Tag__Button"
                              >
                                <span className="Polaris-Icon">
                                  <svg
                                    viewBox="0 0 20 20"
                                    className="Polaris-Icon__Svg"
                                    focusable="false"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </span>
                              </button></td>
                            </tr>
                          </tbody>
                        </table>
                       
                      ))
                      : null}
                  </div>
                ) : null}

                {productList.length > 0 ? (
                  <div
                    id="ResourceListContainer" /*onBlur={handleProductListHide}*/
                  >
                    <ResourceList
                      items={productList}
                      renderItem={(item) => {
                        const { id, title, price, handle } = item;
                        return (
                          <ResourceItem
                            id={id}
                            // url={'/customer'}
                            onClick={() => handleResourceItem(id, title, price, handle)}
                            accessibilityLabel={`View details for ${title}`}
                          >
                            {price != null ? (
                              <div>
                                {title} | {formatMoney(price)}
                              </div>
                            ) : (
                              <div>{title}</div>
                            )}
                          </ResourceItem>
                        );
                      }}
                    // selectable
                    />
                  </div>
                ) : null}
            </TextContainer>
          </Modal.Section>
        </Modal>

        {successToast}
        {errorToast}
      </Page>
    </div>
    </Frame>
  );
};

export default OrderDetail;
