import React, { Component } from 'react';  
import { Route } from 'react-router-dom';  
  
const LoginLayout = ({ children },props) => (                         
   
<div>
  {children}
 
  </div>


    
  );  
  

  
  const LoginLayoutRoute = ({component: Component, ...rest}) => {  
    return (  
      <Route {...rest} render={(props) => (  
        <LoginLayout>  
            <Component {...props} />  
        </LoginLayout>  
      )} />  
    )  
  };  
  
export default LoginLayoutRoute;  